import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { QuickAccess } from 'core/models/cms/QuickAccessCms'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getQuickAccessCms } from '../quickAccess/quickAccess.slice'
import { getShortContactLanguage } from './getShortContactLanguage'

export const getQuickAccessLocalized = createSelector(
    [getLang, getShortContactLanguage, getQuickAccessCms],
    (lang, contactLang, quickAccessesCms): QuickAccess[] => {
        return quickAccessesCms.map((quickAccessCms) => {
            if (lang === ENGLISH_LANGUAGE && !quickAccessCms.heading_en) {
                return getLocalizedObject(quickAccessCms, contactLang) as QuickAccess
            } else {
                return getLocalizedObject(quickAccessCms, lang) as QuickAccess
            }
        })
    }
)

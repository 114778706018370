import './AssuraCarouselArrow.css'

import { ArrowProps } from 'react-multi-carousel'

import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface AssuraCarouselArrowProps {
    direction: 'left' | 'right'
    preventInfiniteLoop: () => void
}

type FullAssuraCarouselArrowProps = AssuraCarouselArrowProps & ArrowProps

const AssuraCarouselArrow = ({
    onClick,
    direction,
    preventInfiniteLoop
}: FullAssuraCarouselArrowProps): JSX.Element => {
    const handleOnClick = (): void => {
        preventInfiniteLoop()
        if (onClick) onClick()
    }

    return (
        <AssuraIconButton
            id={`assura-carousel-arrow-button-${direction}`}
            icon={`assura-${direction}`}
            variant="secondary"
            size="medium"
            onClick={handleOnClick}
            classNames={`assura-carousel-arrow assura-carousel-arrow-${direction}`}
        />
    )
}

export default AssuraCarouselArrow

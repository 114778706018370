import './MobileAppSplashScreen.css'

import fallbackImage from 'shared/assets/images/appstore-fr.png'
import AssuraEcLink from 'shared/components/AssuraEcLink/AssuraEcLink'

import { localizedBadges } from './mobileLocalizedBadges'

interface AppStoreBadgeProps {
    storeLink: string
    image: localizedBadges
}
const AppStoreBadge = ({ storeLink, image }: AppStoreBadgeProps) => (
    <div className="moa-ss-appstore-badge-frame d-flex align-items-center justify-content-center">
        <AssuraEcLink
            ecLinkId={storeLink}
            testId="moa-ss-appstore-badge"
            classNames="moa-ss-appstore-badge d-flex align-items-center justify-content-center"
            as="div"
        >
            <div
                className="moa-ss-appstore-badge d-flex align-items-center justify-content-center"
                style={{ backgroundImage: `url(${image.appStore ?? fallbackImage})` }}
            ></div>
        </AssuraEcLink>
    </div>
)
export default AppStoreBadge

import './AssuraAvatar.css'

import useWindowSize from 'core/services/useWindowSize'

interface AssuraAvatarLabel {
    title: string
    classNames: string
    marginTop: number
}

interface AssuraAvatarBorder {
    width: number
    color: string
}

interface AssuraAvatarProps {
    id: string
    children: React.ReactNode
    mainContainerWidth?: number
    avatarContainerWidth: number
    label?: AssuraAvatarLabel | undefined
    border?: AssuraAvatarBorder | undefined
    active?: boolean | undefined
    hasBadge?: boolean
    badgeColor?: string
    inactiveBackgroundColor?: string
}

const AssuraAvatar = ({
    id,
    children,
    mainContainerWidth,
    avatarContainerWidth,
    label,
    border,
    active,
    hasBadge,
    badgeColor,
    inactiveBackgroundColor
}: AssuraAvatarProps): JSX.Element => {
    const { isMobile } = useWindowSize()

    let avatarClass = `avatar-container bg-${
        active ? 'primary' : inactiveBackgroundColor || 'gray300'
    } c-white`

    if (border && !active) avatarClass += ` bc-${border.color}`
    return (
        <div className="avatar-main-container" style={{ width: mainContainerWidth }}>
            {hasBadge && (
                <div
                    className={`avatar-badge-container bg-${badgeColor || 'black'} bc-white`}
                    data-testid={`${id}-badge`}
                />
            )}
            <div
                className={avatarClass}
                style={{
                    width: avatarContainerWidth,
                    height: avatarContainerWidth,
                    borderStyle: border && !active ? 'solid' : 'none',
                    borderWidth: border && !active ? `${border.width}px` : '0'
                }}
                data-testid={id}
            >
                {children}
            </div>
            {label && (
                <div
                    data-testid={`${id}-label`}
                    className={`max-2-text-lines text-center ${label.classNames}`}
                    style={{
                        maxWidth: !isMobile ? `${mainContainerWidth}px` : 'auto',
                        marginTop: `${label.marginTop}px`
                    }}
                >
                    {label.title}
                </div>
            )}
        </div>
    )
}

export default AssuraAvatar

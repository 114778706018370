import { DEFAULT_LANGUAGE } from 'core/constants/lang'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { HeadOfFamily } from 'core/models/HeadOfFamily'
import { RedirectionParameters } from 'core/models/RedirectionParameters'

import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface DataInitData {
    reload?: boolean
}

interface GeneralState {
    redirectionParameters: RedirectionParameters
    lang: string
    userUtn?: number
    headOfFamily?: HeadOfFamily
    initDataLoadingStatus: LoadingStatusEnum
    locationPathname: string
}

const INITIAL_STATE_GENERAL: GeneralState = {
    redirectionParameters: {
        form: undefined,
        lang: undefined,
        origin: '',
        goToServices: false,
        goToPage: '',
        visitOrigin: ''
    },
    lang: DEFAULT_LANGUAGE,
    userUtn: undefined,
    headOfFamily: undefined,
    initDataLoadingStatus: LoadingStatusEnum.LOADING,
    locationPathname: '',
}

export const generalSlice = createSlice({
    name: 'general',
    initialState: INITIAL_STATE_GENERAL,
    reducers: {
        initData: (state, _action: PayloadAction<DataInitData>) => {
            return state
        },
        setRedirectionParameters: (state, action: PayloadAction<RedirectionParameters>) => {
            state.redirectionParameters = { ...state.redirectionParameters, ...action.payload }
        },
        setUserUtn: (state, action: PayloadAction<number | undefined>) => {
            state.userUtn = action.payload
        },
        resetSlices: (state) => {
            return state
        },
        setLang: (state, _action: PayloadAction<string>) => {
            return state
        },
        setLangSuccess: (state, action: PayloadAction<string>) => {
            state.lang = action.payload
        },
        postDeviceInfo: (state) => {
            return state
        },
        setHeadOfFamily: (state, action: PayloadAction<HeadOfFamily>) => {
            state.headOfFamily = action.payload
        },
        setInitDataLoadingStatus: (state, action: PayloadAction<LoadingStatusEnum>) => {
            state.initDataLoadingStatus = action.payload
        },
        setLocationPathname: (state, action: PayloadAction<string>) => {
            state.locationPathname = action.payload
        },
        validateCgu: (state) => {
            return state
        }
    }
})

export const {
    initData,
    setRedirectionParameters,
    setUserUtn,
    resetSlices,
    setLang,
    setLangSuccess,
    postDeviceInfo,
    setHeadOfFamily,
    setInitDataLoadingStatus,
    setLocationPathname,
    validateCgu
} = generalSlice.actions

export const getRedirectionParameters: Selector<RootState, RedirectionParameters> = (state) =>
    state.general.redirectionParameters

export const getUserUtn: Selector<RootState, number | undefined> = (state) => state.general.userUtn

export const getLang: Selector<RootState, string> = (state) => state.general.lang

export const getPath: Selector<RootState, string> = (state) => state.general.locationPathname

export const getHeadOfFamily: Selector<RootState, HeadOfFamily | undefined> = (state) =>
    state.general.headOfFamily

export const getInitDataLoadingStatus: Selector<RootState, LoadingStatusEnum> = (state) =>
    state.general.initDataLoadingStatus

export default generalSlice.reducer

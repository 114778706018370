import './NewsCard.css'

import { useTranslation } from 'react-i18next'

import analyticsConstants from 'core/constants/analyticsConstants'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { News } from 'core/models/News'
import useOpenFormOrLink from 'core/services/useOpenFormOrLink'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'

interface NewsCardProps {
    news: News
}

const NewsCard = ({ news }: NewsCardProps): JSX.Element => {
    const { t } = useTranslation()
    const openFormOrLink = useOpenFormOrLink()

    const onClick = (): void => {
        openFormOrLink(news.link_url)
        sendEvent(analyticsConstants.EVENTS.BANNER_CLICK, {
            [analyticsConstants.PARAMS.HREF]: news.link_url,
            [analyticsConstants.PARAMS.CREATIVE_ID]: news._id
        })
    }

    return (
        <div
            className="news-card-container-frame border-solid-width-1 bc-gray100"
            onClick={onClick}
        >
            <div
                className="news-card-container"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_CMS_ASSET}${news.image?.path})`
                }}
                data-testid={`${news._id}-container`}
            />
            <div className={`bottom-info d-flex bg-white flex-column`}>
                <div
                    className={`d-flex flex-column flex-1 m-top-32  m-left-32  m-right-32 m-bottom-0`}
                >
                    <div
                        className={`titleSmall max-2-text-lines`}
                        data-testid={`${news._id}-title`}
                    >
                        {news.title}
                    </div>
                    {news.description && (
                        <div
                            className={`paragraphSmallLight m-top-8 max-3-text-lines`}
                            data-testid={`${news._id}-description`}
                        >
                            {news.description}
                        </div>
                    )}
                </div>
                {news.link_url && (
                    <div className="learn-more-button">
                        <AssuraIconButton
                            id={`${news._id}-learn-more-button`}
                            icon="assura-next"
                            variant="primary"
                            size="medium"
                            label={t('COMMON.LEARN_MORE')}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default NewsCard

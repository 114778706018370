export default {
    EVENTS: {
        PAGE_VIEW: 'page_view',
        FORM_SEND: 'form_send',
        REQUEST_ITEM_DETAILS: 'request_item_details',
        DOC_VIEW: 'doc_view',
        DOC_DOWNLOAD: 'doc_download',
        BANNER_CLICK: 'banner_click',
        PRODUCT_CARD_CLICK: 'product_card_click',
        OFFER_SUGGEST_CLICK: 'offer_suggest_click',
        FORM_SEND_ERROR: 'form_send_error',
        OPEN_CCM_PDF: 'open_ccm_pdf',
        OPEN_CCM_IFRAME: 'open_ccm_iframe',
        FILTER: 'filter',
        SELECT_TOPIC: 'select_topic',
        WEB_FORM_SELECTION: 'web_form_selection',
        SELECT_FAQ: 'select_faq',
        NO_RELEVANT_ANSWER_FOUND: 'no_relevant_answer_found',
        ACCOUNT_DELETE: 'account_delete'
    },
    PARAMS: {
        APP_LANGUAGE: 'app_language',
        IS_LIGHT_AUTH_MODE: 'is_light_auth_mode',
        IS_BACKOFFICE_USER: 'is_backoffice_user',
        CHAT_ORIGIN: 'chat_origin',
        PAGE_NAME: 'page_name',
        PAGE_TYPE: 'page_type',
        CONTACT_LANGUAGE: 'contact_language',
        INSURANCE_COMBI: 'insurance_combi',
        FAMILY_COMPOSITION: 'family_composition',
        FORM_CATEGORY: 'form_category',
        REQUEST_PURPOSE: 'request_purpose',
        CREATIVE_ID: 'creative_id',
        HREF: 'href',
        PRODUCT_CATEGORY: 'product_category',
        PRODUCT_TITLE: 'product_title',
        LINK_TITLE: 'link_title',
        BUTTON_ORIGIN: 'button_origin',
        SCREEN_WIDTH: 'screen_width',
        SCREEN_BREAKPOINT: 'screen_breakpoint',
        ZOOM_LEVEL: 'zoom_level',
        DEFAULT_FONTSIZE: 'default_fontsize',
        MINIMUM_FONTSIZE: 'minimum_fontsize',
        EC_VERSION: 'ec_version',
        CCM_URI: 'ccm_uri',
        FILTER_CONTEXT: 'filter_context',
        FILTER_TYPE: 'filter_type',
        FILTER_VALUE: 'filter_value',
        TOPIC_CATEGORY: 'topic_category',
        VISIT_ORIGIN: 'visit_origin',
        FAQ_QUESTION: 'faq_question'
    }
}

import { Route, Routes } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import Communications from 'modules/communications/pages/Communications'
import EnveloppesBackToScan from 'modules/enveloppes/pages/EnveloppesBackToScan'
import EnveloppesSubmission from 'modules/enveloppes/pages/EnveloppesSubmission'
import Faq from 'modules/faq/pages/Faq'
import Financials from 'modules/financials/pages/Financials'
import Home from 'modules/home/pages/Home'
import LamalRules from 'modules/howItWorks/pages/LamalRules'
import Insurances from 'modules/insurances/pages/Insurances'
import PersonalData from 'modules/personalData/pages/PersonalData'
import Search from 'modules/search/pages/Search'
import DeleteAccount from 'modules/settings/pages/DeleteAccount'
import Settings from 'modules/settings/pages/Settings'
import ServicesRoutes from 'ServicesRoutes'

import AssuraNotFound from './shared/components/AssuraNotFound/AssuraNotFound'

const AppRoutes = (): JSX.Element => {
    //Keep in mind adding props when adding routes otherwise the 404 page will not be able to handle case like newPage/doesNotExists
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path={navigationConstants.HOME.url} element={<Home />} />
            <Route path={navigationConstants.INSURANCES.url} element={<Insurances />} />
            <Route
                path={navigationConstants.ENVELOPPES_SUBMISSION.url}
                element={<EnveloppesSubmission />}
            />
            <Route
                path={navigationConstants.ENVELOPPES_BACK_TO_SCAN.url}
                element={<EnveloppesBackToScan />}
            />
            <Route path="/Services/*" element={<ServicesRoutes />} />
            <Route path={navigationConstants.PERSONAL_DATA.url} element={<PersonalData />} />
            <Route path={navigationConstants.SETTINGS.url} element={<Settings />} />
            <Route path={navigationConstants.FAQ.url} element={<Faq />} />
            <Route path={navigationConstants.SEARCH.url} element={<Search />} />
            <Route path={navigationConstants.FINANCIALS.url} element={<Financials />} />
            <Route path={navigationConstants.COMMUNICATIONS.url} element={<Communications />} />
            <Route path={navigationConstants.DELETE_ACCOUNT.url} element={<DeleteAccount />} />
            <Route path={navigationConstants.LAMAL_RULES.url} element={<LamalRules />} />
            <Route path="*" element={<AssuraNotFound />} />
        </Routes>
    )
}

export default AppRoutes

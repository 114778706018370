import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { AssuraDocumentType } from 'core/enums/DocumentType'
import { TranslatedEnveloppeStatus } from 'core/enums/EnveloppeStatus'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { DateFilterSelectionValue } from 'core/helpers/CommonAssuraTableFilters'
import { EnveloppeRow } from 'core/models/enveloppes/EnveloppeRow'
import { TableFilterState } from 'core/models/TableFilter'
import useQuery from 'core/services/useQuery'
import EnveloppesPanel from 'modules/enveloppes/components/EnveloppesPanel/EnveloppesPanel'
import AssuraTable from 'shared/components/AssuraTable/AssuraTable'
import { fetchEnveloppeImageByName } from 'shared/store/enveloppeImages/enveloppeImages.slice'
import {
    getEnveloppeLoadingStatus,
    getSelectedEnveloppeIdState,
    onFetchEnveloppes,
    setSelectedEnveloppeId
} from 'shared/store/enveloppes/enveloppes.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getEnveloppesForDataTable } from 'shared/store/selectors/getEnveloppesForDataTable'

import getEnveloppesFilterState from './EnveloppesTableFilters'
import { getFilteredEnveloppes } from './EnveloppesTableHelper'
import EnveloppesTableRow from './EnveloppesTableRow'

export type EnveloppesFilterSelectionValue =
    | number
    | TranslatedEnveloppeStatus
    | DateFilterSelectionValue

const FinancialsEnveloppes = (): JSX.Element => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const lang = useSelector(getLang)

    const query = useQuery()
    const externalPolicyNumber = query.get('policyNumber')

    const AllEnveloppes = useSelector(getEnveloppesForDataTable)
    const hasRescanStatus = AllEnveloppes.some(
        (env) => env.statusTranslated === TranslatedEnveloppeStatus.Rescan
    )

    const selectedEnveloppeId = useSelector(getSelectedEnveloppeIdState)
    const enveloppesLoadingStatus = useSelector(getEnveloppeLoadingStatus)

    const paginationIncrement = 10
    const [pagination, setPagination] = useState(paginationIncrement)

    const [enveloppes, setEnveloppes] = useState<EnveloppeRow[]>([])
    const paginatedEnveloppes = enveloppes.slice(0, pagination)

    const initialFilters = getEnveloppesFilterState(
        hasRescanStatus,
        externalPolicyNumber ? Number(externalPolicyNumber) : undefined
    )

    const [filters, setFilters] = useState<TableFilterState<EnveloppesFilterSelectionValue>>({
        ...initialFilters
    })

    const onFilterSelection = (
        newFilterState: TableFilterState<EnveloppesFilterSelectionValue>
    ) => {
        setFilters({ ...newFilterState })
    }

    const onRowSelection = (row: EnveloppeRow) => {
        dispatch(setSelectedEnveloppeId(row.id))
    }

    const onMoreSelection = () => {
        setPagination((prev) => (prev += paginationIncrement))
    }

    useEffect(() => {
        const newEnveloppes = getFilteredEnveloppes(AllEnveloppes, filters)
        setEnveloppes([...newEnveloppes])
    }, [AllEnveloppes, pagination, filters])

    useEffect(() => {
        const selection = paginatedEnveloppes.find(
            (enveloppe) => enveloppe.id === selectedEnveloppeId
        )

        if (selection) {
            selection.documents.map((doc) => {
                dispatch(
                    fetchEnveloppeImageByName({
                        fileName: doc.fileName,
                        type: doc.documentType ?? AssuraDocumentType.JPG
                    })
                )
            })
            setTimeout(() => sendEvent(analyticsConstants.EVENTS.DOC_VIEW), 100)
        }
    }, [selectedEnveloppeId, paginatedEnveloppes, lang])

    return (
        <>
            <div className="side-navigation-content-table">
                <AssuraTable
                    id="enveloppes"
                    rows={paginatedEnveloppes}
                    rowComponent={(row) => <EnveloppesTableRow row={row} />}
                    filters={filters}
                    loadStatus={enveloppesLoadingStatus}
                    paginationStatus={enveloppesLoadingStatus}
                    filteringStatus={enveloppesLoadingStatus}
                    onReload={() => dispatch(onFetchEnveloppes())}
                    onFilterChange={onFilterSelection}
                    onRowSelection={onRowSelection}
                    noResultMessages={{
                        datas: { label: t('ENVELOPPES.NO_DOCUMENT_MESSAGE') },
                        filters: { label: t('ENVELOPPES.NO_DOCUMENT_FOUND_MESSAGE') }
                    }}
                    hasMore={enveloppes.length > pagination}
                    onMoreSelection={onMoreSelection}
                />
            </div>
            <EnveloppesPanel />
        </>
    )
}

export default FinancialsEnveloppes

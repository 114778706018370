import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react'

import { LinkType } from 'core/enums/LinkType'
import {
    getCalculatorMethod,
    getErrorMethod,
    getExternalMethod,
    getFormMethod,
    getInternalMethod,
    getWhatsNewMethod
} from 'core/helpers/LinkHelper'
import { AssuraLinkType } from 'core/models/AssuraLinkType'
import { ServicesRouteQuery } from 'core/models/services/ServicesRoute'

export type AssuraLinkProps<C extends ElementType> = {
    testId: string
    link_url?: string
    type?: AssuraLinkType
    linkQuery?: ServicesRouteQuery
    as?: C
    classNames?: string
    callback?: (link_url: string) => void
    children: ReactNode
} & ComponentPropsWithoutRef<C>

const AssuraLink = <C extends ElementType = 'div'>({
    testId,
    link_url,
    type,
    linkQuery,
    as,
    classNames,
    callback,
    children,
    ...restProps
}: AssuraLinkProps<C>): JSX.Element => {
    const As = as || 'div'

    let method = getErrorMethod(testId)

    if (type && link_url) {
        switch (type) {
            case LinkType.FORM:
                method = getFormMethod(link_url, linkQuery)
                break
            case LinkType.INTERNAL:
                method = getInternalMethod(link_url)
                break
            case LinkType.EXTERNAL_CMS:
            case LinkType.EXTERNAL:
                method = getExternalMethod(link_url, testId)
                break
            case LinkType.CALCULATOR:
                method = getCalculatorMethod(link_url, testId)
                break
            case LinkType.WHATS_NEW:
                method = getWhatsNewMethod(link_url, testId)
                break
        }
    }

    const handleOnClick = () => {
        if (callback && link_url) callback(link_url)
        method()
    }
    return (
        <As {...restProps} className={classNames} onClick={handleOnClick} data-testid={testId}>
            {children}
        </As>
    )
}

export default AssuraLink

import axios from 'axios'
import { LoginForm } from 'core/models/services/settings/LoginForm'
import { PasswordForm } from 'core/models/services/settings/PasswordForm'
import { UpdateResult } from 'shared/store/settings/credentials/credentials.slice'

export const submitUpdateLoginForm = async (login: LoginForm): Promise<string | UpdateResult> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/UpdateLogin`, login)

export const submitUpdatePasswordForm = async (
    password: PasswordForm
): Promise<string | UpdateResult> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/UpdatePassword`, password)

export const fetchEcPlusLastDate = async (): Promise<Date | null> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/popupecplus`)
    return result.data.length === 0 ? null : result.data
}

export const submitEcPlusActivation = async (activateEcPlus = false): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/popupecplus`, {
        activateEcPlus
    })

export const submitDeleteAccountApi = async (policyNumber: number): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/webaccountdeactivate`, {
        policyNumber
    })

export const showQualiMedRemind = async (): Promise<boolean> => {
    const result = await axios.get(
        `${process.env.REACT_APP_API_COMMON}/api/v1/Account/showqualimedremind`
    )
    return result.data
}

export const updateQualiMedRemind = async (): Promise<void> =>
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v1/Account/updatequalimedremind`)

export const fetchLastWhatsNewId = async (): Promise<number> => {
    const result = await axios.get(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/lastwhatsnewid`)
    return result.data
}

export const updateFamilyLastWhatsNewApi = async (whatsNewId: number): Promise<void> => {
    axios.post(`${process.env.REACT_APP_API_COMMON}/api/v2/Account/updatewhatsnewid`, {
        whatsNewId
    })
}

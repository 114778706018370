/*
 * doctor required = a family doctor is needed for the product
 * doctor mandatory = a family doctor must be informed immediately for the product
 */

import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getDateFromDayMonthYear } from 'core/helpers/DateFormatHelper'
import { PharmaFile } from 'core/helpers/services/ServicesHelper'
import { ProductCms } from 'core/models/cms/ProductCms'
import { Doctor } from 'core/models/familyGroup/Doctor'
import useWindowSize from 'core/services/useWindowSize'
import { formatNumber } from 'core/utils/TextUtils'
import CheckboxRHF from 'modules/services/components/CheckboxRHF/CheckboxRHF'
import ChooseDoctor from 'modules/services/contract/components/ChooseDoctor/ChooseDoctor'
import AssuraCmsLink from 'shared/components/AssuraCmsLink/AssuraCmsLink'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getProductsCms } from 'shared/store/products/products.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getDoctorProductCodes, getDoctors } from 'shared/store/services/coverage/coverage.slice'

enum DoctorOptions {
    NO_DOCTOR_OPTION = '',
    CURRENT_DOCTOR_OPTION = 'current-doctor-option',
    NEW_DOCTOR_OPTION = 'new-doctor-option',
    FIRST_DOCTOR_OPTION = 'first-doctor-option',
    LATER_DOCTOR_OPTION = 'later-doctor-option'
}

interface ModelDoctorHeaderProps {
    beginDate: string
}

const ModelDoctorHeader = ({ beginDate }: ModelDoctorHeaderProps): JSX.Element | null => {
    const { t } = useTranslation()
    const dateLabel = t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR_AT_DATE')

    return (
        <>
            <div className="labelMedium">{t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR')}</div>
            <div className="labelExtraSmall c-gray500">{`${dateLabel} ${beginDate}`}</div>
        </>
    )
}

interface ModelDoctorInfoProps {
    singleColumn?: boolean
}

const ModelDoctorInfo = ({ singleColumn }: ModelDoctorInfoProps): JSX.Element | null => {
    /* useTranslation is defined here to avoid
     * "Rendered fewer or more hooks" on window resize */
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const commonClassNames = 'paragraphSmall c-black bg-gray20 bc-gray100 p-32 characteristics'
    const topMargin = singleColumn ? ' m-top-32' : ''

    const content = (
        <div className={`${commonClassNames}${topMargin}`} style={{ whiteSpace: 'pre-line' }}>
            {t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR_INFO')}
        </div>
    )

    if ((singleColumn && !isMobile) || (!singleColumn && isMobile)) return null
    return singleColumn ? (
        content
    ) : (
        <Col md={6} xl={5}>
            {content}
        </Col>
    )
}

interface CurrentDoctorInModelOrNotProps {
    currentDoctor: Doctor | null
    doctorProductCodes: string[]
    model: string
}

const CurrentDoctorInModelOrNot = ({
    currentDoctor,
    doctorProductCodes,
    model
}: CurrentDoctorInModelOrNotProps): JSX.Element | null => {
    const { t } = useTranslation()

    const classNames =
        currentDoctor && doctorProductCodes.includes(model)
            ? 'assura-check-circle c-success'
            : 'assura-error c-error'
    const iconTestId =
        currentDoctor && doctorProductCodes.includes(model)
            ? 'doctor-check-icon'
            : 'doctor-error-icon'
    const textKey = () => {
        if (!currentDoctor) return 'SERVICES.MODEL_DEDUCTIBLE_DOCTOR_NO_CURRENT'
        if (doctorProductCodes.includes(model)) return 'SERVICES.MODEL_DEDUCTIBLE_DOCTOR_IN_MODEL'
        return 'SERVICES.MODEL_DEDUCTIBLE_DOCTOR_NOT_IN_MODEL'
    }

    return (
        <div className="m-top-48 d-flex">
            <i className={`icon ${classNames} size-24 m-right-12`} data-testid={iconTestId} />
            {currentDoctor
                ? t(textKey(), {
                      firstName: currentDoctor.firstName,
                      lastName: currentDoctor.lastName
                  })
                : t(textKey())}
        </div>
    )
}

interface DoctorChoiceProps {
    doctorRequired: boolean
    doctorMandatory: boolean
    currentDoctor: Doctor | null
    doctorProductCodes: string[]
    model: string
    doctorOption: DoctorOptions
    changeDoctorOption: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const DoctorChoice = ({
    doctorRequired,
    doctorMandatory,
    currentDoctor,
    doctorProductCodes,
    model,
    doctorOption,
    changeDoctorOption
}: DoctorChoiceProps): JSX.Element | null => {
    const { t } = useTranslation()

    if (!doctorRequired) return null

    // Has compatible doctor
    const displayCurrentAndNewOptions = currentDoctor && doctorProductCodes.includes(model)
    // Doctor selection is not mandatory
    const displayFirstOption = !doctorMandatory && !displayCurrentAndNewOptions

    return (
        <>
            {(displayCurrentAndNewOptions || displayFirstOption) && (
                <div className="paragraphMedium m-top-24">
                    {t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR_CHOICE')}
                </div>
            )}
            {displayCurrentAndNewOptions && (
                <>
                    <RadioButtonDoctor
                        mTop="24"
                        id={DoctorOptions.CURRENT_DOCTOR_OPTION}
                        checked={doctorOption === DoctorOptions.CURRENT_DOCTOR_OPTION}
                        changeDoctorOption={changeDoctorOption}
                        label="CURRENT"
                    />
                    <RadioButtonDoctor
                        mTop="16"
                        id={DoctorOptions.NEW_DOCTOR_OPTION}
                        checked={doctorOption === DoctorOptions.NEW_DOCTOR_OPTION}
                        changeDoctorOption={changeDoctorOption}
                        label="NEW"
                    />
                </>
            )}
            {displayFirstOption && (
                <RadioButtonDoctor
                    mTop="16"
                    id={DoctorOptions.FIRST_DOCTOR_OPTION}
                    checked={doctorOption === DoctorOptions.FIRST_DOCTOR_OPTION}
                    changeDoctorOption={changeDoctorOption}
                    label="FIRST"
                />
            )}
            {!doctorMandatory && (
                <RadioButtonDoctor
                    mTop="16"
                    id={DoctorOptions.LATER_DOCTOR_OPTION}
                    checked={doctorOption === DoctorOptions.LATER_DOCTOR_OPTION}
                    changeDoctorOption={changeDoctorOption}
                    label="LATER"
                />
            )}
        </>
    )
}

interface RadioButtonDoctorProps {
    mTop: string
    id: string
    checked: boolean
    changeDoctorOption: (e: React.ChangeEvent<HTMLInputElement>) => void
    label: string
}

const RadioButtonDoctor = ({
    mTop,
    id,
    checked,
    changeDoctorOption,
    label
}: RadioButtonDoctorProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className={`custom-control custom-radio m-top-${mTop}`}>
            <input
                name="doctor-update-radio"
                id={id}
                data-testid={id}
                value={id}
                className="custom-control-input"
                type="radio"
                checked={checked}
                onChange={changeDoctorOption}
            />
            <label htmlFor={id} className="custom-control-label paragraph form-check-label">
                {t(`SERVICES.MODEL_DEDUCTIBLE_DOCTOR_${label}`)}
            </label>
        </div>
    )
}

interface ViewChooseDoctorProps {
    model: string
    doctorOption: DoctorOptions
    doctorRequired: boolean
}

const ViewChooseDoctor = ({
    model,
    doctorOption,
    doctorRequired
}: ViewChooseDoctorProps): JSX.Element | null => {
    if (
        (doctorOption === DoctorOptions.NO_DOCTOR_OPTION && doctorRequired) ||
        doctorOption === DoctorOptions.NEW_DOCTOR_OPTION ||
        doctorOption === DoctorOptions.FIRST_DOCTOR_OPTION
    )
        return (
            <div className="model-deductible-doctor-choice">
                <ChooseDoctor code={model} />
            </div>
        )

    return null
}

interface PharmaFileElementProps {
    productCms: ProductCms
}

const PharmaFileElement = ({ productCms }: PharmaFileElementProps): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isMobile, isMD } = useWindowSize()
    const assets = useSelector(getAssets)
    const responsiveMargin = isMobile || isMD ? 'm-top-8' : 'm-left-8'

    const pharmaFileLinkText = t('SERVICES.MODEL_DEDUCTIBLE_PHARMACIES_LINK')

    const handleError = () => {
        dispatch(
            setBanner({
                dataTestId: 'pharma-pdf-error',
                message: 'COMMON.ERROR'
            })
        )
    }

    if (PharmaFile(productCms.insurance_id, assets))
        return (
            <AssuraCmsLink
                asset={PharmaFile(productCms.insurance_id, assets)}
                title={pharmaFileLinkText}
                classNames={`paragraphSmallLight assura-link ${responsiveMargin}`}
            />
        )

    return (
        <div
            onClick={handleError}
            className={`paragraphSmallLight assura-link cursor-pointer ${responsiveMargin}`}
        >
            {t(pharmaFileLinkText)}
        </div>
    )
}

const ModelDeductibleCharacteristics = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { isMobile, isMD } = useWindowSize()
    const [doctorOption, setDoctorOption] = useState<DoctorOptions>(DoctorOptions.NO_DOCTOR_OPTION)

    const doctors = useSelector(getDoctors)
    const doctorProductCodes = useSelector(getDoctorProductCodes)
    const productsInformation = useSelector(getProductsCms)

    const {
        watch,
        setValue,
        getValues,
        register,
        clearErrors,
        formState: { errors }
    } = useFormContext()

    const modelWatch = watch('model')
    const deductibleWatch = watch('deductible')
    const beginDateWatch = watch('beginDate')

    const productCMS = productsInformation.filter((pi) => pi.insurance_id === modelWatch)[0]

    const changeDoctorOption = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(
            'newDoctorId',
            (e.target.value as DoctorOptions) === DoctorOptions.LATER_DOCTOR_OPTION
                ? '9999989'
                : undefined
        )
        setValue('searchDoctorName', '')
        setDoctorOption(e.target.value as DoctorOptions)
    }

    let filteredDoctors: Doctor[] = []
    if (beginDateWatch) {
        const beginDateAsDate = getDateFromDayMonthYear(beginDateWatch)
        filteredDoctors = doctors.filter(
            (x) =>
                beginDateAsDate > new Date(x.startDate) &&
                (x.endDate === null || beginDateAsDate < new Date(x.endDate))
        )
    }

    const currentDoctor = filteredDoctors.length === 1 ? filteredDoctors[0] : null

    useEffect(() => {
        setValue('newDoctorId', undefined)
        if (
            productCMS.family_doctor &&
            currentDoctor &&
            doctorProductCodes.includes(productCMS.insurance_id)
        ) {
            setDoctorOption(DoctorOptions.CURRENT_DOCTOR_OPTION)
        } else if (productCMS.family_doctor && productCMS.family_doctor_mandatory === false) {
            setDoctorOption(DoctorOptions.FIRST_DOCTOR_OPTION)
        } else {
            setDoctorOption(DoctorOptions.NO_DOCTOR_OPTION)
        }
    }, [productCMS, currentDoctor, doctorProductCodes])

    const validateDoctorLater = () => {
        const { doctorLater } = getValues()
        if (doctorLater) {
            clearErrors('doctorLater')
            return true
        } else {
            return 'SERVICES.MODEL_DEDUCTIBLE_DOCTOR_LATER_ERROR'
        }
    }

    const validatePharmacies = () => {
        const { pharmacies } = getValues()
        if (pharmacies) {
            clearErrors('pharmacies')
            return true
        } else {
            return 'SERVICES.MODEL_DEDUCTIBLE_PHARMACIES_ERROR'
        }
    }

    const doctorLaterError = t(errors?.doctorLater?.message as string)
    const pharmaciesError = t(errors?.pharmacies?.message as string)
    if (!productCMS || (!productCMS.family_doctor && !productCMS.recognised_pharmacies)) return null

    return (
        <>
            <div className="headline m-top-56 c-black">
                {t('SERVICES.MODEL_DEDUCTIBLE_CHARACTERISTICS')}
            </div>
            <div className="labelSmall c-gray500 m-top-8">
                {productCMS.title.toUpperCase()} -{' '}
                {`${t('SERVICES.MODEL_DEDUCTIBLE_DEDUCTIBLE_LABEL')} ${formatNumber(
                    deductibleWatch,
                    false
                )}`}
            </div>
            {productCMS.family_doctor && (
                <FullScreenContainer>
                    <Container>
                        <Row className="bg-white bc-gray100 m-top-32 p-top-32 p-bottom-32 p-left-16 p-right-16 characteristics">
                            <Col xs={12} md={6} xl={7}>
                                <ModelDoctorHeader beginDate={beginDateWatch} />
                                <ModelDoctorInfo singleColumn={true} />
                                <CurrentDoctorInModelOrNot
                                    currentDoctor={currentDoctor}
                                    doctorProductCodes={doctorProductCodes}
                                    model={modelWatch}
                                />
                                {/*
                                 * family_doctor_mandatory default value in the cms is true,
                                 * but if not set manually it returns empty
                                 * {productCMS.family_doctor_mandatory !== false}
                                 */}
                                <DoctorChoice
                                    doctorRequired={productCMS.family_doctor}
                                    doctorMandatory={productCMS.family_doctor_mandatory !== false}
                                    currentDoctor={currentDoctor}
                                    doctorProductCodes={doctorProductCodes}
                                    model={modelWatch}
                                    doctorOption={doctorOption}
                                    changeDoctorOption={changeDoctorOption}
                                />
                                <ViewChooseDoctor
                                    model={modelWatch}
                                    doctorOption={doctorOption}
                                    doctorRequired={productCMS.family_doctor}
                                />
                            </Col>
                            <ModelDoctorInfo />
                        </Row>
                    </Container>
                </FullScreenContainer>
            )}
            {(doctorOption === DoctorOptions.LATER_DOCTOR_OPTION ||
                productCMS.recognised_pharmacies) && (
                <div className="bg-white bc-gray100 m-top-32 p-32 characteristics">
                    {doctorOption === DoctorOptions.LATER_DOCTOR_OPTION && (
                        <>
                            <div className="labelMedium">
                                {t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR_LATER_TITLE')}
                            </div>
                            <div className="m-top-32">
                                <CheckboxRHF
                                    id="doctorLater"
                                    name="doctorLater"
                                    label={t('SERVICES.MODEL_DEDUCTIBLE_DOCTOR_LATER_LABEL')}
                                    register={register}
                                    rules={{
                                        validate: validateDoctorLater
                                    }}
                                    labelClassNames="small"
                                />
                                {doctorLaterError && (
                                    <div
                                        className="labelExtraSmall c-primary m-top-8"
                                        data-testid="services-doctor-later-error"
                                    >
                                        {doctorLaterError}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {productCMS.recognised_pharmacies && (
                        <>
                            <div
                                className={`labelMedium ${
                                    doctorOption === DoctorOptions.LATER_DOCTOR_OPTION
                                        ? 'm-top-32'
                                        : ''
                                }`}
                            >
                                {t('SERVICES.MODEL_DEDUCTIBLE_PHARMACIES')}
                            </div>
                            <div
                                className={`d-flex m-top-32 ${
                                    isMobile || isMD ? 'flex-column align-items-start' : ''
                                }`}
                            >
                                <div className="d-flex flex-column">
                                    <CheckboxRHF
                                        id="pharmacies"
                                        name="pharmacies"
                                        label={t('SERVICES.MODEL_DEDUCTIBLE_PHARMACIES_CHECK', {
                                            model: productCMS.title
                                        })}
                                        register={register}
                                        rules={{
                                            validate: validatePharmacies
                                        }}
                                        labelClassNames="small"
                                    />
                                    {pharmaciesError && (
                                        <div
                                            className="labelExtraSmall c-primary m-top-8"
                                            data-testid="services-pharmacies-error"
                                        >
                                            {pharmaciesError}
                                        </div>
                                    )}
                                </div>
                                <div className={`${isMobile || isMD ? 'm-left-32' : ''}`}>
                                    <PharmaFileElement productCms={productCMS} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default ModelDeductibleCharacteristics

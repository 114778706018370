import { GERMAN_LANGUAGE, ITALIAN_LANGUAGE } from 'core/constants/lang'
import { ServicesSource, Source } from 'core/enums/ServicesSource'
import authService from 'index'
import { setRedirectionParameters } from 'shared/store/general/general.slice'
import store from 'shared/store/store'

import { AuthenticationService } from '../authentication/authentication'
import { RedirectionParameters } from '../models/RedirectionParameters'

const getUrlRedirectionParameters = (url: string): RedirectionParameters => {
    return Object.fromEntries(new URLSearchParams(url)) as unknown as RedirectionParameters
}

const getStorageRedirectionParameters = (): RedirectionParameters | undefined => {
    const storageParameters = localStorage.getItem('redirectionParameters')
    return storageParameters
        ? (Object.fromEntries(
              new URLSearchParams(storageParameters)
          ) as unknown as RedirectionParameters)
        : undefined
}

const websitePostLogoutUri = (lang: string | undefined): string => {
    const uriPath =
        lang === GERMAN_LANGUAGE
            ? '/de/service-support/service-und-support'
            : lang === ITALIAN_LANGUAGE
              ? '/it/assistenza-supporto/assistenza-e-supporto'
              : '/fr/services-support/services-et-support'

    return process.env.REACT_APP_PUBLIC + uriPath
}

const websiteAuthentication = (signedInCallback: () => void, lang: string | undefined) =>
    new AuthenticationService({
        authority: process.env.REACT_APP_AUTH_SERVER ?? '',
        client_id: '7288e250-f5fa-4015-90fb-50c533bd85bb',
        scope: 'openid offline_access self_service_forms',
        redirect_uri: `${window.location.origin}/signin-callback-website.html`,
        post_logout_redirect_uri: websitePostLogoutUri(lang),
        ui_locales: lang,
        signedInCallback
    })

const supervisionAuthentication = (signedInCallback: () => void, lang: string | undefined) =>
    new AuthenticationService({
        authority: process.env.REACT_APP_AUTH_SERVER ?? '',
        client_id: '1cfa7648-7b27-45df-9ab1-25c1316d511d',
        scope: 'openid offline_access impersonation full',
        redirect_uri: `${window.location.origin}/signin-callback-windows.html`,
        post_logout_redirect_uri: window.location.origin,
        ui_locales: lang,
        loadUserInfo: true,
        signedInCallback
    })

const customerAuthentication = (signedInCallback: () => void) =>
    new AuthenticationService({
        authority: process.env.REACT_APP_AUTH_SERVER ?? '',
        client_id: 'c39546d3-100a-41e0-8631-9b6cd3f3de49',
        scope: 'openid offline_access full',
        redirect_uri: `${window.location.origin}/signin-callback.html`,
        post_logout_redirect_uri: window.location.origin,
        signedInCallback
    })

export const getAuthenticationConfiguration = (
    signedInCallback: () => void
): AuthenticationService => {
    const urlParams = window.location.search

    //Convert query parameters to json object
    const urlRedirectionParameters = getUrlRedirectionParameters(urlParams)
    const storageRedirectionParameters = getStorageRedirectionParameters()

    if (Object.keys(urlRedirectionParameters).length > 0 || storageRedirectionParameters) {
        store.dispatch(
            setRedirectionParameters(
                Object.keys(urlRedirectionParameters).length > 0
                    ? urlRedirectionParameters
                    : (storageRedirectionParameters as RedirectionParameters)
            )
        )
    }

    if (urlParams) {
        localStorage.setItem('redirectionParameters', urlParams)
    }

    const isFromWebsite = Source() === ServicesSource.WEBSITE
    const isFromBackoffice = Source() === ServicesSource.BACKOFFICE

    const lang = urlRedirectionParameters.lang ?? storageRedirectionParameters?.lang

    if (isFromWebsite) return websiteAuthentication(signedInCallback, lang)
    if (isFromBackoffice) return supervisionAuthentication(signedInCallback, lang)
    return customerAuthentication(signedInCallback)
}

// ToDo: permissions.includes('65') must be removed once the migration to Active Directory management is done.
export const hasWritePermission = (): boolean => {
    if (Source() !== ServicesSource.BACKOFFICE) return true
    const permissions = authService.User?.profile?.permissions as string
    return permissions.includes('65') || permissions.includes('boReadWrite')
}

export const hasFinancialWritePermission = (): boolean => {
    if (Source() !== ServicesSource.BACKOFFICE) return true
    const permissions = authService.User?.profile?.permissions as string
    return permissions.includes('boFinanceReadWrite')
}

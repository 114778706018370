import { ServiceTopicFront } from './ServiceTopic'

export enum PageType {
    HOME_PAGE = 'HomePage',
    ENVELOPPES_PAGE = 'EnvelopesPage',
    DOCUMENTS_PAGE = 'DocumentsPage',
    INSURANCES_PAGE = 'InsurancesPage',
    CONTACT_PAGE = 'ContactPage',
    MY_ACCOUNT_PAGE = 'MyAccountPage',
    SERVICES_PAGE = 'ServicesPage',
    FAQ_PAGE = 'FaqPage',
    SEARCH_PAGE = 'SearchPage',
    ERROR_PAGE = 'ErrorPage',
    DELETE_ACCOUNT_PAGE = 'DeleteAccountPage',
    LAMAL_RULES_PAGE = 'HowItWorksPage'
}

export enum PageName {
    ROOT = '/',
    HOME = '/Home',
    DELETE_ACCOUNT = '/DeleteAccount',
    ENVELOPPES_SUBMISSION = '/Enveloppes/Submission',
    ENVELOPPES_BACK_TO_SCAN = '/Enveloppes/BackToScan',
    FINANCIALS_INVOICES = '/Financials/Invoices',
    FINANCIALS_REFUNDS = '/Financials/Refunds',
    FINANCIALS_ENVELOPPES = '/Financials/Enveloppes',
    COMMUNICATIONS_CORRESPONDENCE = '/Communications/Correspondence',
    COMMUNICATIONS_CONTRACTUAL = '/Communications/Contractual',
    INSURANCES = '/Insurances',
    PERSONAL_DATA = '/PersonalData',
    SETTINGS = '/Settings',
    LAMAL_RULES = '/HowItWorks',
    SERVICES_HUB = '/Services',
    SERVICES_ITEMS = '/Services/All',
    SERVICES_AVENTURA_INSURANCE_REQUEST = '/Services/Offers/Aventura',
    SERVICES_SUCCESS = '/Services/Success',
    SERVICES_SUBMITTED_REQUESTS = '/Services/SubmittedRequests',
    SERVICES_CERTIFICATES_MEDICAL_FEES = '/Services/Certificates/MedicalFees',
    SERVICES_CERTIFICATES_BILLED_PRIME = '/Services/Certificates/BilledPrime',
    SERVICES_CERTIFICATES_INSURANCE_ATTESTATION = '/Services/Certificates/InsuranceAttestation',
    SERVICES_CERTIFICATES_PROVISIONAL_REPLACEMENT_CERTIFICATE = '/Services/Certificates/ProvisionalReplacementCertificate',
    SERVICES_CERTIFICATES_STATEMENT_DETAILS = '/Services/Certificates/StatementDetails',
    SERVICES_CERTIFICATES_DELEGATION = '/Services/Certificates/Delegation',
    SERVICES_INSURANCE_CARD_ORDER = '/Services/Certificates/InsuranceCardOrder',
    SERVICES_COVERAGE_MODEL_DEDUCTIBLE = '/Services/Coverage/ModelDeductible',
    SERVICES_COVERAGE_LAMAL_ACCIDENT = '/Services/Contract/LamalAccident',
    SERVICES_CONTRACT_FAMILY_DOCTOR = '/Services/Contract/FamilyDoctor',
    SERVICES_FINANCIAL_PAYMENT_CHANGE = '/Services/Financial/PaymentChange',
    SERVICES_FINANCIAL_PAYMENT_FREQUENCY_MODIFICATION = '/Services/Financial/PaymentFrequencyModification',
    SERVICES_PERSONAL_DATA_INFOS = '/Services/PersonalData/Infos',
    SERVICES_PERSONAL_DATA_ADDRESS = '/Services/PersonalData/Address',
    SERVICES_CONTACT_GENERAL_QUESTION = '/Services/Contact/GeneralQuestion',
    SERVICES_FAMILY_GROUP_ADD_MEMBER = '/Services/Family/AddMember',
    SERVICES_REPORT_HOSPITALIZATION_ANNOUNCEMENT = '/Services/Report/HospitalizationAnnouncement',
    SERVICES_REPORT_ACCIDENT_REPORT = '/Services/Report/AccidentReport',
    SERVICES_REPORT_TRIP_CANCELLATION = '/Services/Report/TripCancellation',
    FAQ = '/Faq',
    SEARCH = '/Search',
    NOT_FOUND = '/404'
}

export enum InsuranceCombi {
    NONE = 'none',
    LAMAL = 'LAMal',
    LCA = 'LCA',
    LAMAL_LCA = 'LAMal & LCA'
}

export enum FormCategory {
    CONTACT = 'contact',
    SUBMISSION = 'submission',
    SERVICES = 'services',
    PERSONAL_DATA = 'personalData'
}

export enum ServicesRequestPurpose {
    MEDICAL_FEES = 'MedicalFees',
    BILLED_PRIME = 'BilledPrime',
    INSURANCE_ATTESTATION = 'InsuranceAttestation',
    AVENTURA = 'Aventura',
    PROVISIONAL_REPLACEMENT_CERTIFICATE = 'ProvisionalReplacementCertificate',
    INSURANCE_CARD = 'InsuranceCard',
    STATEMENT_DETAILS = 'StatementDetails',
    MODEL_DEDUCTIBLE = 'ModelDeductible',
    FAMILY_DOCTOR = 'FamilyDoctor',
    ADDRESS = 'Address',
    PAYMENT_CHANGE = 'PaymentChange',
    LAMAL_ACCIDENT = 'LamalAccident',
    PAYMENT_FREQ_MODIFICATION = 'PaymentFrequencyModification',
    DELEGATION = 'Delegation',
    ADD_MEMBER = 'AddMember',
    HOSPITALIZATION_ANNOUNCEMENT = 'HospitalizationAnnouncement',
    ACCIDENT_REPORT = 'AccidentReport'
}

export enum PersonalDataRequestPurpose {
    BASIC_INFO = 'basicInfo',
    PHONE = 'phone',
    EMAIL = 'email'
}

export enum SubmissionRequestPurpose {
    INVOICE = 'invoice'
}

export type RequestPurpose =
    | ServiceTopicFront
    | ServicesRequestPurpose
    | PersonalDataRequestPurpose
    | SubmissionRequestPurpose

export enum LinkTitle {
    NEW_OFFER = 'New offer',
    SEE_LCA = 'See LCA products',
    SEE_LAMAL = 'See LAMal products'
}

export enum ButtonOrigin {
    HEADER = 'header',
    DEMARCHES = 'demarches',
    DETAILS_PRIMES = 'details_primes'
}

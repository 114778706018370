import { combineReducers } from '@reduxjs/toolkit'

import appSearchReducer from './appSearch/appSearch.slice'
import assetsReducer from './assets/assets.slice'
import authenticationReducer from './authentication/authentication.slice'
import bannersReducer from './banners/banners.slice'
import claimsSettlementReducer from './claimsSettlement/claimsSettlement.slice'
import communicationsReducer from './communications/communications.slice'
import digcliParametersReducer from './digcliParameters/digcliParameters.slice'
import documentsReducer from './documents/documents.slice'
import ecLinksReducer from './ecLinks/ecLinks.slice'
import enveloppeImagesReducer from './enveloppeImages/enveloppeImages.slice'
import enveloppesReducer from './enveloppes/enveloppes.slice'
import familyMemberReducer from './familyMember/familyMember.slice'
import familyPicturesReducer from './familyPictures/familyPictures.slice'
import familySummariesReducer from './familySummaries/familySummaries.slice'
import faqReducer from './faq/faq.slice'
import financialDocumentsReducer from './financialDocuments/financialDocuments.slice'
import generalReducer from './general/general.slice'
import generalQuestionTopicReducer from './generalQuestionTopic/generalQuestionTopic.slice'
import invoicesToCheckReducer from './invoicesToCheck/invoicesToCheck.slice'
import localitiesReducer from './localities/localities.slice'
import missingDataReducer from './missingData/missingData.slice'
import newsReducer from './news/news.slice'
import notificationsReducer from './notifications/notifications.slice'
import popinReducer from './popin/popin.slice'
import productsReducer from './products/products.slice'
import qrCodesReducer from './qrCodes/qrCodes.slice'
import quickAccessReducer from './quickAccess/quickAccess.slice'
import accidentReportReducer from './services/accidentReport/accidentReport.slice'
import servicesAddressReducer from './services/address/address.slice'
import aventuraReducer from './services/aventura/aventura.slice'
import servicesCertificatesReducer from './services/certificates/certificates.slice'
import servicesContactReducer from './services/contact/contact.slice'
import servicesContactInfosReducer from './services/contactInfos/contactInfos.slice'
import servicesCoverageReducer from './services/coverage/coverage.slice'
import servicesDoctorsReducer from './services/doctors/doctors.slice'
import servicesFamilyGroupReducer from './services/familyGroup/familyGroup.slice'
import servicesFinancialReducer from './services/financial/financial.slice'
import servicesPeriodicityReducer from './services/financial/periodicity.slice'
import servicesFrequentRequestsReducer from './services/frequentRequests/frequentRequests.slice'
import servicesReportReducer from './services/report/report.slice'
import servicesRequestsReducer from './services/requests/requests.slice'
import servicesReducer from './services/services.slice'
import credentialsReducer from './settings/credentials/credentials.slice'
import deleteAccountReducer from './settings/deleteAccount/deleteAccount.slice'
import ecPlusMigrationReducer from './settings/ecPlusMigration/ecPlusMigration.slice'
import qualiMedRemindReducer from './settings/qualiMedRemind/qualiMedRemind.slice'
import toastReducer from './toast/toast.slice'
import unpaidStatsReducer from './unpaidStats/unpaidStats.slice'
import whatsNewReducer from './whatsNew/whatsNew.slice'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = () =>
    combineReducers({
        authentication: authenticationReducer,
        aventura: aventuraReducer,
        accidentReport: accidentReportReducer,
        credentials: credentialsReducer,
        familySummaries: familySummariesReducer,
        familyPictures: familyPicturesReducer,
        familyMember: familyMemberReducer,
        products: productsReducer,
        enveloppeImages: enveloppeImagesReducer,
        enveloppes: enveloppesReducer,
        general: generalReducer,
        generalQuestionTopic: generalQuestionTopicReducer,
        toast: toastReducer,
        documents: documentsReducer,
        financialDocuments: financialDocumentsReducer,
        qrCodes: qrCodesReducer,
        localities: localitiesReducer,
        servicesContactInfos: servicesContactInfosReducer,
        notifications: notificationsReducer,
        unpaidStats: unpaidStatsReducer,
        missingData: missingDataReducer,
        news: newsReducer,
        quickAccess: quickAccessReducer,
        assets: assetsReducer,
        banners: bannersReducer,
        faq: faqReducer,
        ecLinks: ecLinksReducer,
        servicesAddress: servicesAddressReducer,
        servicesFinancial: servicesFinancialReducer,
        servicesPeriodicity: servicesPeriodicityReducer,
        services: servicesReducer,
        servicesCertificates: servicesCertificatesReducer,
        servicesDoctors: servicesDoctorsReducer,
        servicesCoverage: servicesCoverageReducer,
        servicesReport: servicesReportReducer,
        servicesRequests: servicesRequestsReducer,
        servicesContact: servicesContactReducer,
        servicesFamilyGroup: servicesFamilyGroupReducer,
        servicesFrequentRequests: servicesFrequentRequestsReducer,
        communications: communicationsReducer,
        appSearch: appSearchReducer,
        digcliParameters: digcliParametersReducer,
        ecPlusMigration: ecPlusMigrationReducer,
        claimsSettlement: claimsSettlementReducer,
        deleteAccount: deleteAccountReducer,
        invoicesToCheck: invoicesToCheckReducer,
        qualiMedRemind: qualiMedRemindReducer,
        whatsNew: whatsNewReducer,
        popin: popinReducer
    })

export default rootReducer

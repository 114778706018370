import './WhatsNewItem.css'

import { ReactNode } from 'react'

import { formatDate } from 'core/helpers/DateFormatHelper'
import { WhatsNewPanel } from 'core/models/WhatsNew'
import { GetAssetIcon } from 'core/utils/assetUtils'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'

interface WhatsNewItemProps {
    date: string
    panel: WhatsNewPanel
    ctaComponent: ReactNode | null
    isMultiplePanels: boolean
}

const WhatsNewItem = ({
    date,
    panel,
    ctaComponent,
    isMultiplePanels
}: WhatsNewItemProps): JSX.Element => {
    const icon = GetAssetIcon(panel.icon || '')

    return (
        <div className="whats-new-item-container">
            <div className="whats-new-item-message-content">
                {icon ? (
                    <img
                        src={icon}
                        className="whats-new-item-icon"
                        alt={panel.icon || ''}
                        data-testid="whats-new-pop-in-item-icon"
                    />
                ) : (
                    <div className="labelSmall" data-testid="whats-new-pop-in-item-date">
                        {formatDate(date)}
                    </div>
                )}
                <div
                    className="headlineLarge_vesterbro m-top-16"
                    data-testid="whats-new-pop-in-item-title"
                >
                    {panel.title}
                </div>
                <div className="m-top-24 flex-1" data-testid="whats-new-pop-in-item-description">
                    <AssuraMarkdown message={panel.description} />
                </div>
                {!!ctaComponent && isMultiplePanels && (
                    <div className="m-top-24">{ctaComponent}</div>
                )}
            </div>
            {!isMultiplePanels && <div className="m-top-24 m-bottom-32">{ctaComponent}</div>}
        </div>
    )
}

export default WhatsNewItem

import './ServicesHub.css'

import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reactPlugin } from 'AppInsights'
import { AbsolutePosition } from 'core/enums/AbsolutePosition'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getServices } from 'core/helpers/services/ServicesHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraLoadAndError from 'shared/components/AssuraLoadAndError/AssuraLoadAndError'
import FullScreenContainer from 'shared/components/FullScreenContainer/FullScreenContainer'
import { getLastRequests } from 'shared/store/selectors/getLastRequests'
import {
    fetchFrequentRequests,
    getFrequentRequestsLoadingStatus
} from 'shared/store/services/frequentRequests/frequentRequests.slice'
import {
    getRequests,
    getServicesRequestsLoadingStatus
} from 'shared/store/services/requests/requests.slice'
import { useAppDispatch } from 'shared/store/store'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import AllRequests from '../components/AllRequests/AllRequests'
import DiscoveryRequests from '../components/DiscoveryRequests/DiscoveryRequests'
import FrequentRequests from '../components/FrequentRequests/FrequentRequests'
import LastRequests from '../components/LastRequests/LastRequests'

const ServicesHub = (): JSX.Element => {
    const { t } = useTranslation()
    const { isMobile, isXL } = useWindowSize()
    const dispatch = useAppDispatch()

    const requests = useSelector(getLastRequests)
    const shouldDisplayLastRequests = requests.length > 0

    const cards = getServices()

    const discoveryCards = cards.find((card) => card.id === '06')
    const allCards = cards.filter((card) => card.id !== '06')

    const lastRequestsLoadingStatus = useSelector(getServicesRequestsLoadingStatus)
    const frequentRequestsLoadingStatus = useSelector(getFrequentRequestsLoadingStatus)

    const globalHeaderLoadingStatus =
        lastRequestsLoadingStatus === LoadingStatusEnum.LOADING
            ? LoadingStatusEnum.LOADING
            : LoadingStatusEnum.OK

    const reloadGlobalRequests = () => {
        dispatch(fetchFrequentRequests())
        dispatch(getRequests())
    }

    useEffect(() => {
        dispatch(getRequests())
    }, [])

    return (
        <>
            <FullScreenContainer color="gray50">
                <Container className="p-top-56 p-bottom-40">
                    <span className="title break-word" data-testid="services-title">
                        {t('CONTACT.SERVICES_TITLE')}
                    </span>
                </Container>
                <Container>
                    <Row className="services-hub-header-row">
                        <AssuraLoadAndError
                            status={globalHeaderLoadingStatus}
                            defaultReloadAction={reloadGlobalRequests}
                        >
                            <Col
                                xs={12}
                                md={shouldDisplayLastRequests ? 6 : 12}
                                xl={shouldDisplayLastRequests ? 8 : 12}
                                className="d-flex flex-column"
                            >
                                <div
                                    className="text-left headlineSmall m-bottom-8"
                                    data-testid="services-hub-frequent-requests-title"
                                >
                                    {t('SERVICES.HUB_FREQUENT_REQUESTS')}
                                </div>
                                <FrequentRequests
                                    isFullWidth={!shouldDisplayLastRequests}
                                    loadingStatus={frequentRequestsLoadingStatus}
                                />
                            </Col>
                            {shouldDisplayLastRequests && !isMobile && (
                                <Col xs={12} md={6} xl={4} className="d-flex flex-column">
                                    <LastRequests
                                        requests={requests}
                                        loadingStatus={lastRequestsLoadingStatus}
                                    />
                                </Col>
                            )}
                        </AssuraLoadAndError>
                    </Row>
                </Container>
            </FullScreenContainer>
            <FullScreenContainer color="white" complementaryClasses="p-top-56 p-bottom-48 flex-1">
                <AllRequests items={allCards} />
            </FullScreenContainer>
            <FullScreenContainer
                color="gray50"
                secondColoredOffset={
                    isXL
                        ? {
                              secondColorClass: 'bg-white',
                              bootStrapColumnNumber: 2,
                              position: AbsolutePosition.RIGHT
                          }
                        : undefined
                }
            >
                <Container className="services-hub-discovery-block">
                    <Row>
                        <>
                            {shouldDisplayLastRequests && isMobile && (
                                <Col xs={12} className="m-bottom-80">
                                    <LastRequests
                                        requests={requests}
                                        loadingStatus={lastRequestsLoadingStatus}
                                    />
                                </Col>
                            )}
                            {discoveryCards && <DiscoveryRequests item={discoveryCards} />}
                        </>
                    </Row>
                </Container>
            </FullScreenContainer>
        </>
    )
}

export default withAITracking(reactPlugin, ServicesHub, 'Services', 'services-hub-container')

import './AssuraCarouselDot.css'

import React from 'react'
import { DotProps } from 'react-multi-carousel'

const AssuraCarouselDot = ({ onClick, active }: DotProps): JSX.Element => {
    return (
        <li className="assura-carousel-dot">
            <button
                data-testid={`assura-carousel-dot-button-${active ? 'active' : 'not-active'}`}
                className={`assura-carousel-dot-button ${active ? 'bg-primary' : 'bg-gray100'}`}
                onClick={() => onClick && onClick()}
            />
        </li>
    )
}

export default AssuraCarouselDot

import { getLocalizedObject } from 'core/helpers/ObjectHelper'
import { FrequentRequest } from 'core/models/FrequentRequest'

import { createSelector } from '@reduxjs/toolkit'

import { getLang } from '../general/general.slice'
import { getFilteredFrequentRequests } from './getFilteredFrequentRequests'

export const getFrequentRequests = createSelector([getFilteredFrequentRequests, getLang], (filteredRequests, lang) => {
    return filteredRequests.map((filteredReq) => {
        const localizedReq = getLocalizedObject(filteredReq, lang)
        return localizedReq as FrequentRequest
    })
})

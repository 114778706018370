import { isDate } from 'date-fns'

export const dateIntervalCheck = (from: string, to: string | null, check: string): boolean => {
    let lDate = null
    const fDate = Date.parse(from)

    if (to) {
        lDate = Date.parse(to)
    }
    const cDate = Date.parse(check)
    return (!lDate || cDate <= lDate) && cDate >= fDate
}

// gets the difference in days between 2 dates
export const getDiffDays = (date1: Date, date2: Date): number =>
    Math.abs(Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)))

export interface FormatedDateOptions {
    separator: '.' | '-' | '/'
    format: 'dd.MM.yyyy' | 'yyyy.MM.dd'
}

export const getFormatedDate = (
    date: string | Date,
    options: FormatedDateOptions = { separator: '.', format: 'dd.MM.yyyy' }
): string | undefined => {
    if (!date) return date

    let dateTmpObject = new Date()

    if (isDate(date)) {
        dateTmpObject = new Date(date)
    }

    const day = dateTmpObject.getDate().toString().padStart(2, '0')
    const month = (dateTmpObject.getMonth() + 1).toString().padStart(2, '0')
    const year = dateTmpObject.getFullYear().toString()
    return options.format === 'dd.MM.yyyy'
        ? `${day}${options.separator}${month}${options.separator}${year}`
        : `${year}${options.separator}${month}${options.separator}${day}`
}

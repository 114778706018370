import './DiscoveryRequests.css'

import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ServicesHubItem } from 'core/models/services/ServicesHub'
import image from 'shared/assets/images/services-discovery.png'
import { getAssets } from 'shared/store/selectors/getAssets'

import RequestsEcLink from '../RequestsEcLink/RequestsEcLink'

interface DiscoveryRequestsProps {
    item: ServicesHubItem
}

const DiscoveryRequests = ({ item }: DiscoveryRequestsProps): JSX.Element => {
    const { t } = useTranslation()

    const links = item.links
    const assets = useSelector(getAssets)

    return (
        <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <div className="services-hub-discovery-requests-container">
                <div
                    className="services-hub-discovery-requests-image"
                    style={{ backgroundImage: `url(${ assets.Image_OnlineServices?.fr ?? image})` }}
                />
                <div className="services-hub-discovery-requests-message">
                    <span className="headlineLarge">{t('SERVICES.DISCOVERY_TITLE')}</span>
                    <span className="paragraphLight m-top-24">
                        {t('SERVICES.DISCOVERY_MESSAGE')}
                    </span>
                </div>
                {links ? (
                    <div className="services-hub-discovery-requests-links-container">
                        <div className="requests-list-container">
                            <>
                                {links.map((link) => (
                                    <RequestsEcLink
                                        link={link}
                                        key={`discovery-${link.ecLinkId}`}
                                    />
                                ))}
                            </>
                        </div>
                    </div>
                ) : null}
            </div>
        </Col>
    )
}

export default DiscoveryRequests

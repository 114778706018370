import './AllRequestsItem.css'

import { useEffect, useRef, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getServices } from 'core/helpers/services/ServicesHelper'
import { ServicesHubItem } from 'core/models/services/ServicesHub'
import useQuery from 'core/services/useQuery'
import useScrollTo from 'core/services/useScrollTo'

import AllRequestsItemRecto from './AllRequestsItemRecto'
import AllRequestsItemVerso from './AllRequestsItemVerso'

interface AllRequestsItemProps {
    item: ServicesHubItem
}

const AllRequestsItem = ({ item }: AllRequestsItemProps): JSX.Element => {
    const { t } = useTranslation()

    const [isSelected, setIsSelected] = useState(false)

    const cards = getServices()
    const cardRefs = cards.reduce<Record<string, React.RefObject<HTMLDivElement>>>((acc, value) => {
        acc[value.id] = useRef<HTMLDivElement>(null)
        return acc
    }, {})

    const toggleSelection = () => {
        setIsSelected((prev) => !prev)
    }

    const cardQuery = useQuery().get('card')

    useEffect(() => {
        if (cardQuery === item.id) {
            setIsSelected(true)
            if (cardRefs[cardQuery]) {
                useScrollTo(cardRefs[cardQuery])
            }
        }
    }, [cardQuery])

    return (
        <Col
            xs={12}
            md={6}
            xl={4}
            onClick={toggleSelection}
            data-testid={`services-hub-all-requests-item-${item.id}`}
        >
            <div
                className={`services-hub-all-requests-item-container${isSelected ? ' active' : ''}`}
                ref={cardRefs[item.id]}
            >
                {isSelected ? (
                    <AllRequestsItemVerso item={item} />
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center flex-1">
                        <AllRequestsItemRecto item={item} />
                        <div className="services-hub-all-requests-item-show-details icon-container primary">
                            <i
                                className="icon assura-next size-24"
                                data-testid="services-hub-all-requests-item-show-details-icon"
                            />
                            <div
                                className="link-label labelSmall m-left-8"
                                data-testid="services-hub-all-requests-item-show-details-label"
                            >
                                {t('COMMON.SEE_DETAIL')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Col>
    )
}

export default AllRequestsItem

import ReactMarkdown from 'react-markdown'
import { SpecialComponents } from 'react-markdown/lib/ast-to-react'
import { NormalComponents } from 'react-markdown/lib/complex-types'

import useOpenFormOrLink from 'core/services/useOpenFormOrLink'
import remarkGfm from 'remark-gfm'

import AssuraEcLink from '../AssuraEcLink/AssuraEcLink'

interface AssuraEcLinkMarkdownProps {
    message: string
    components?:
        | Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>
        | undefined
    testId?: string
}

const AssuraEcLinkMarkdown = ({
    message,
    components,
    testId = ''
}: AssuraEcLinkMarkdownProps): JSX.Element => {
    const openFormOrLink = useOpenFormOrLink()

    const defaultComponents: Partial<
        Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
    > = {
        a: (props) => {
            const { children, href } = props

            if (href?.startsWith('/')) {
                return (
                    <span className="assura-link" onClick={() => openFormOrLink(href)}>
                        {children[0]}
                    </span>
                )
            } else {
                return (
                    <AssuraEcLink
                        ecLinkId={href ?? ''}
                        testId={testId}
                        classNames="assura-link"
                        as="span"
                    >
                        {children[0]}
                    </AssuraEcLink>
                )
            }
        }
    }

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                ...defaultComponents,
                ...components
            }}
            children={message}
            className="assura-markdown"
        />
    )
}

export default AssuraEcLinkMarkdown

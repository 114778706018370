import './News.css'

import { ResponsiveType } from 'react-multi-carousel'
import { useSelector } from 'react-redux'

import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'
import { getNews } from 'shared/store/selectors/getNews'

import NewsCard from '../NewsCard/NewsCard'

const News = (): JSX.Element => {
    const news = useSelector(getNews)

    const responsive: ResponsiveType = {
        all: {
            breakpoint: { max: 10000, min: 0 },
            items: 1
        }
    }

    const carouselItems = news?.map((newsItem) => <NewsCard key={newsItem._id} news={newsItem} />)

    return (
        <AssuraCarousel
            carouselItems={carouselItems}
            hasEnoughItem={carouselItems?.length > 1}
            responsive={responsive}
            carouselContainerClass="news-carousel-container"
            carouselDotListClass="news-carousel-dot-list"
            hasInfiniteLoop={carouselItems?.length > 1}
        />
    )
}
export default News

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { AssuraPopinProps } from 'core/models/Popin'
import { assets } from 'core/utils/assetUtils'
import { getSelectedFamilyMember } from 'shared/store/combineSelectors'
import { fetchInitialPolicyNumber } from 'shared/store/familyMember/familyMember.slice'
import { onSubmitQualiMedRemindUpdate } from 'shared/store/settings/qualiMedRemind/qualiMedRemind.slice'

import AssuraPopin from '../AssuraPopin/AssuraPopin'

const AssuraQualiMedPopin = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { policyNumber } = useSelector(getSelectedFamilyMember)

    const qualimedUnderstood = () => {
        dispatch(onSubmitQualiMedRemindUpdate())
    }

    const qualimedReadMore = () => {
        dispatch(onSubmitQualiMedRemindUpdate())
        dispatch(fetchInitialPolicyNumber({ initialPolicyNumber: policyNumber }))
        navigate(`${navigationConstants.LAMAL_RULES.url}?product=PAM`)
    }

    const messageComplement = () => {
        return (
            <div style={{ textAlign: 'left' }}>
                <div className="d-flex m-top-32">
                    <img src={assets.megafon} alt="megafon" width={36} height={36} />
                    <span className="c-primary titleSmall m-left-16">
                        {t('QUALIMED_RULES_REMIND.SUBTITLE')}
                    </span>
                </div>
                <div className="d-flex m-top-32 bg-gray50 p-8">
                    <i
                        data-testid="test1"
                        className={`icon assura-info-circle size-16 c-primary`}
                    />
                    <span className="paragraphSmall m-left-8">
                        {t('QUALIMED_RULES_REMIND.RULE_ONE')}
                    </span>
                </div>
                <div className="d-flex m-top-12 bg-gray50 p-8">
                    <i
                        data-testid="test2"
                        className={`icon assura-info-circle size-16 c-primary`}
                    />
                    <span className="paragraphSmall m-left-8">
                        {t('QUALIMED_RULES_REMIND.RULE_TWO')}
                    </span>
                </div>
            </div>
        )
    }

    const popinProps: AssuraPopinProps = {
        showCloseButton: false,
        title: t('QUALIMED_RULES_REMIND.TITLE'),
        message: t('QUALIMED_RULES_REMIND.DESCRIPTION'),
        messageComplement: messageComplement(),
        cancelButtonLabel: t('QUALIMED_RULES_REMIND.UNDERSTOOD'),
        validateButtonLabel: t('QUALIMED_RULES_REMIND.MORE'),
        cancelAction: qualimedUnderstood,
        validatedAction: qualimedReadMore
    }

    return <AssuraPopin popinProps={popinProps} />
}

export default AssuraQualiMedPopin

import './CguPopin.css'

import { useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'

import useWindowSize from 'core/services/useWindowSize'
import { assets } from 'core/utils/assetUtils'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import AssuraMarkdown from 'shared/components/AssuraMarkdown/AssuraMarkdown'
import { validateCgu } from 'shared/store/general/general.slice'
import { resetSelectedPopinItem } from 'shared/store/popin/popin.slice'
import { useAppDispatch } from 'shared/store/store'

const CguPopin = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { isMobile } = useWindowSize()

    const [isCguAccepted, setIsCguAccepted] = useState(false)
    const [shouldDisplayError, setShouldDisplayError] = useState(false)

    const handleOnCheck = (checked: boolean) => {
        setIsCguAccepted(checked)
        if (shouldDisplayError && checked) setShouldDisplayError(false)
    }

    const handleValidation = () => {
        if (isCguAccepted) {
            dispatch(validateCgu())
            dispatch(resetSelectedPopinItem())
        } else {
            setShouldDisplayError(true)
        }
    }

    const newContentKey = 'CGU_ACCEPTATION.NEW_CONTENT'
    const newContentText = t(newContentKey)
    const shouldDisplayNewContent =
        newContentText && newContentText !== '' && newContentText !== newContentKey

    return (
        <RemoveScroll className="cgu-pop-in-content" data-testid="cgu-pop-in-content">
            <img src={assets.contract} alt="cgu-image" width={80} height={80} />
            <span className="headlineSmallMedium m-top-32" data-testid="cgu-pop-in-title">
                {t('CGU_ACCEPTATION.TITLE')}
            </span>
            {shouldDisplayNewContent && (
                <span
                    className="paragraphSmallLight m-top-16 m-bottom-8 text-center"
                    data-testid="cgu-pop-in-message"
                >
                    {t(newContentKey)}
                </span>
            )}
            <div
                className="custom-control custom-checkbox m-top-24 align-self-start"
                data-testid="cgu-popin-checkbox"
            >
                <input
                    id="cgu-popin-checkbox"
                    onClick={(e) => handleOnCheck(e.currentTarget.checked)}
                    data-testid="cgu-popin-checkbox-testid"
                    className="custom-control-input"
                    type="checkbox"
                    defaultChecked={isCguAccepted}
                />
                <FormCheck.Label className="custom-control-label" htmlFor="cgu-popin-checkbox">
                    <AssuraMarkdown
                        message={t('CGU_ACCEPTATION.CHECKBOX_LINK')}
                        classNames="paragraphSmallLight"
                    />
                </FormCheck.Label>
            </div>
            {shouldDisplayError && (
                <span
                    className="labelExtraSmall c-error m-top-8 align-self-start"
                    data-testid="cgu-pop-in-error-message"
                >
                    {t('CGU_ACCEPTATION.ERROR_MESSAGE')}
                </span>
            )}
            <AssuraButton
                text={t('COMMON.CONTINUE')}
                id="cgu-pop-in-validate"
                variant="primary"
                onClick={handleValidation}
                classNames="m-top-32"
                fullWidth={isMobile}
            />
        </RemoveScroll>
    )
}

export default CguPopin

export interface SplashScreensLink {
    ecLinkId: string
    label: string
}

const splashScreenLinks: SplashScreensLink[] = [
    { ecLinkId: '65f003dfc93854f9c603cd82', label: 'SPLASHSCREEN.LINK.APPSTORE' },
    { ecLinkId: '65f005cd3a3ed4d6fe0a4af5', label: 'SPLASHSCREEN.LINK.PLAYSTORE' }
]

export default splashScreenLinks

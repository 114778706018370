import './SplashScreensGroup.css'

import { Container } from 'react-bootstrap'

import MobileAppSplashScreen from './MobileApp/MobileAppSplashScreen'
import OnlineServicesSplashScreen from './OnlineServices/OnlineServicesSplashScreen'

const SplashScreensGroup = (): JSX.Element => {
    return (
        <Container
            className={`splashscreens-frame d-flex align-items-center justify-content-center`}
        >
            <MobileAppSplashScreen />
            <OnlineServicesSplashScreen />
        </Container>
    )
}

export default SplashScreensGroup

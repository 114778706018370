/* eslint-disable sonarjs/cognitive-complexity */
import { call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects'

import axios from 'axios'
import {
    getDoctorProductCodesData,
    getDoctorsData,
    modelDeductibleChangeDatesData,
    modelDeductiblesChangeData,
    submitLamalAccidentForm,
    submitModelDeductibleForm
} from 'core/api/services/Coverage'
import analyticsConstants from 'core/constants/analyticsConstants'
import { FormCategory, ServicesRequestPurpose } from 'core/enums/AnalyticsEnums'
import { HttpResponseEnum } from 'core/enums/HttpResponseEnum'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { ProductGroupCode, ProductTypeEnum } from 'core/enums/Product'
import { ServicesDoctorType } from 'core/enums/ServicesDoctorType'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import {
    formatDate,
    getDateFromDayMonthYear,
    getDateStringFromDayMonthYear
} from 'core/helpers/DateFormatHelper'
import { ProductFranchise } from 'core/models/coverage/ProductFranchise'
import { Doctor } from 'core/models/familyGroup/Doctor'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { ModelDeductibleFormRequestBody } from 'core/models/services/coverage/ModelDeductibleForm'
import { ServicesCommonRequestBody } from 'core/models/services/ServicesCommonRequestBody'
import { getCurrentYear } from 'core/utils/dateUtils'
import { ConvertToProductCodes } from 'core/utils/ProductUtils'
import { ifNull } from 'core/utils/TextUtils'
import { getSummaryCurrentYear } from 'shared/store/combineSelectors'
import {
    fetchProductsByMemberAndByYearAsync,
    fetchSummaryByYearAsync
} from 'shared/store/familySummaries/familySummaries.saga'
import { getLamalWithDoctorCodes } from 'shared/store/selectors/getLamalWithDoctorCodes'
import { getServicesCommonRequestBody } from 'shared/store/selectors/getServicesCommonRequestBody'
import { getSummaryBeginDateYear } from 'shared/store/selectors/getSummaryBeginDateYear'

import { getRequests } from '../requests/requests.slice'
import { formSubmitted, setSubmitServiceStatus } from '../services.slice'
import {
    fetchMemberInformation,
    fetchModelDeductibleDatesSuccess,
    fetchModelDeductibles,
    fetchModelDeductiblesSuccess,
    getCurrentModel,
    getDoctors,
    MODEL_DEDUCTIBLE_UNSELECTED_DATE,
    onSubmitLamalAccident,
    onSubmitModelDeductible,
    setBeginDate,
    setCurrentModelDeductible,
    setDoctorProductCodes,
    setDoctors,
    setModelDeductibleDatesLoaderStatus,
    setModelDeductiblesLoaderStatus,
    setModelDeductiblesWsStatus
} from './coverage.slice'

const SortProductByFranchise = (deductiblesToSort: ProductFranchise[]) => {
    return deductiblesToSort.sort((a, b) => a.franchiseAmount - b.franchiseAmount)
}

export function* fetchModelDeductiblesAsync(action: ReturnType<typeof fetchModelDeductibles>) {
    try {
        yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.LOADING))
        yield put(setModelDeductiblesWsStatus(null))

        if (
            !action.payload.beginDate ||
            action.payload.beginDate === MODEL_DEDUCTIBLE_UNSELECTED_DATE
        ) {
            yield put(fetchModelDeductiblesSuccess({ modelDeductibles: [] }))
            yield put(
                setDoctorProductCodes({
                    doctorProductCodes: []
                })
            )
            yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.OK))
            return
        }
        const modelDeductibles: ProductFranchise[] = yield call(
            modelDeductiblesChangeData,
            action.payload.policyNumber,
            getDateStringFromDayMonthYear(action.payload.beginDate),
            action.payload.simulationDate?.toLocaleString()
        )
        yield put(
            fetchModelDeductiblesSuccess({
                modelDeductibles
            })
        )
        yield put(setModelDeductiblesWsStatus(HttpResponseEnum.SUCCESS))

        const familySummary: SummaryResult = yield select(getSummaryCurrentYear)
        const foundMember = familySummary.insuredPersons.find(
            (i) => i.policyNumber === action.payload.policyNumber
        )
        const beginYear = getDateFromDayMonthYear(action.payload.beginDate).getFullYear()

        // Needed in case of switch back and forward in date selection
        const memberIndex = familySummary.insuredPersons.findIndex(
            (p) => p.policyNumber === action.payload.policyNumber
        )
        yield call(setCurrentModelDeductibleHelper, memberIndex, action.payload.beginDate)

        // Check if it is a 19 years old customer
        if (foundMember && beginYear - new Date(foundMember.dateOfBirth).getFullYear() === 19) {
            const currentYear = action.payload.simulationDate
                ? new Date(action.payload.simulationDate).getFullYear()
                : getCurrentYear()

            // Check if it is a change for a future year
            if (beginYear > currentYear) {
                const deductiblesToSort = [...modelDeductibles]
                const order = SortProductByFranchise(deductiblesToSort)
                yield put(
                    setCurrentModelDeductible({
                        currentDeductible: order[0].franchiseAmount,
                        currentModel: yield select(getCurrentModel)
                    })
                )
            }
        }

        const doctors: Doctor[] = yield select(getDoctors)
        const beginDateAsDate = getDateFromDayMonthYear(action.payload.beginDate)
        const doctorsFiltered = doctors.filter(
            (x) =>
                beginDateAsDate > new Date(x.startDate) &&
                (x.endDate === null || beginDateAsDate < new Date(x.endDate))
        )

        if (doctorsFiltered.length === 0) {
            yield put(
                setDoctorProductCodes({
                    doctorProductCodes: []
                })
            )
            yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.OK))
            return
        }

        // ## TODO: DATA ONLY AVAILABLE IN SYRIUS #############################
        // Get products data
        const zsrNumber = doctorsFiltered[0].zsrNumber as string
        let doctorProductCodesApi: string[] = []
        try {
            doctorProductCodesApi = yield call(getDoctorProductCodesData, zsrNumber)
        } catch (e) {
            if (
                axios.isAxiosError(e) &&
                e.response?.status === HttpResponseEnum.NOT_FOUND &&
                zsrNumber === '9999189'
            ) {
                console.error('Error getting doctor product codes', e)
                doctorProductCodesApi = ['RMd']
            } else {
                throw e
            }
        }
        const lamalWithDoctors: string[] = yield select(getLamalWithDoctorCodes)
        const doctorProductCodes = ConvertToProductCodes(doctorProductCodesApi, lamalWithDoctors)

        // ####################################################################
        // const doctorProductCodes: ProductCodes[] = [ProductCodes.Pharmed]

        yield put(
            setDoctorProductCodes({
                doctorProductCodes
            })
        )

        yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchModelDeductiblesAsync Error', e)
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.BAD_REQUEST) {
            yield put(fetchModelDeductiblesSuccess({ modelDeductibles: [] }))
            yield put(setModelDeductiblesWsStatus(HttpResponseEnum.BAD_REQUEST))
            yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.OK))
        } else {
            yield put(setModelDeductiblesWsStatus(HttpResponseEnum.INTERNAL_ERROR))
            yield put(setModelDeductiblesLoaderStatus(LoadingStatusEnum.ERROR))
        }
    }
}

export function* fetchMemberInformationAsync(action: ReturnType<typeof fetchMemberInformation>) {
    try {
        yield put(setModelDeductibleDatesLoaderStatus(LoadingStatusEnum.LOADING))

        // Get allowed dates
        const modelDeductibleDates: Date[] = yield call(
            modelDeductibleChangeDatesData,
            action.payload.policyNumber,
            action.payload.simulationDate
        )

        yield put(
            fetchModelDeductibleDatesSuccess({
                modelDeductibleDates
            })
        )

        const beginDate =
            modelDeductibleDates.length === 1
                ? formatDate(modelDeductibleDates[0])
                : MODEL_DEDUCTIBLE_UNSELECTED_DATE

        yield put(
            setBeginDate(beginDate === MODEL_DEDUCTIBLE_UNSELECTED_DATE ? undefined : beginDate)
        )

        // Find current member index
        const familySummary: SummaryResult = yield select(getSummaryCurrentYear)
        const memberIndex = familySummary.insuredPersons.findIndex(
            (p) => p.policyNumber === action.payload.policyNumber
        )

        // Get summary and products of selected member for all years retrieved
        const years = modelDeductibleDates
            .map((x) => new Date(x).getFullYear())
            .filter((value, index, self) => self.indexOf(value) === index)

        for (const year of years) {
            yield call<typeof fetchSummaryByYearAsync>(fetchSummaryByYearAsync, {
                payload: {
                    year: year,
                    shouldFetchProducts: false, // we don't use the fetch products here because it is not same memberIndex
                    shouldThrow: true
                },
                type: 'familySummaries/fetchSummaryByYear'
            })
            yield call<typeof fetchProductsByMemberAndByYearAsync>(
                fetchProductsByMemberAndByYearAsync,
                {
                    payload: {
                        year: year,
                        index: memberIndex,
                        shouldThrow: true
                    },
                    type: 'familySummaries/fetchProductsByMemberAndByYear'
                }
            )
        }

        yield call(setCurrentModelDeductibleHelper, memberIndex, beginDate)

        // Get doctors data
        const doctors: Doctor[] = yield call(getDoctorsData, action.payload.policyNumber)
        yield put(
            setDoctors({
                doctors
            })
        )

        yield put(setModelDeductibleDatesLoaderStatus(LoadingStatusEnum.OK))
    } catch (e) {
        console.error('fetchMemberInformationAsync Error', e)
        yield put(setModelDeductibleDatesLoaderStatus(LoadingStatusEnum.ERROR))
    }
}

function* onSubmitModelDeductibleSaga(action: ReturnType<typeof onSubmitModelDeductible>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.MODEL_DEDUCTIBLE
    }
    try {
        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))

        const modelDeductibleForm = action.payload
        const formToSubmit: ModelDeductibleFormRequestBody = {
            policyNumber: modelDeductibleForm.selectedPolicyNumber as number,
            beginDate: getDateStringFromDayMonthYear(action.payload.beginDate as string),
            franchise: {
                product: modelDeductibleForm.model,
                franchiseAmount: +modelDeductibleForm.deductible,
                group: ProductGroupCode.BASIC,
                prime: 0,
                longName: '',
                shortName: ''
            },
            newDoctorId:
                modelDeductibleForm.doctorType === ServicesDoctorType.PEDIATRICIAN
                    ? '9999189'
                    : (modelDeductibleForm.newDoctorId as string)
        }
        yield call(submitModelDeductibleForm, formToSubmit, modelDeductibleForm.simulationDate)

        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitModelDeductibleSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* setCurrentModelDeductibleHelper(memberIndex: number, beginDate: string) {
    if (beginDate === MODEL_DEDUCTIBLE_UNSELECTED_DATE) return
    const familySummaryWithProducts: SummaryResult = yield select(getSummaryBeginDateYear)
    const lamalProducts = familySummaryWithProducts.insuredPersons[
        memberIndex
    ].productsPerson?.products?.filter(
        (p) =>
            p.type === ProductTypeEnum.LAMAL &&
            new Date(p.startDate) <= getDateFromDayMonthYear(beginDate)
    )

    // if many, get the one with the most recent date
    const lamalProduct = lamalProducts?.sort(
        (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    )[0]

    yield put(
        setCurrentModelDeductible({
            currentDeductible: lamalProduct ? lamalProduct.deductible : null,
            currentModel: lamalProduct ? lamalProduct.code : null
        })
    )
}

function* onSubmitLamalAccidentSaga(action: ReturnType<typeof onSubmitLamalAccident>) {
    const analyticsParams = {
        form_category: FormCategory.SERVICES,
        request_purpose: ServicesRequestPurpose.LAMAL_ACCIDENT
    }
    try {
        const commonRequestBody: ServicesCommonRequestBody = yield select(
            getServicesCommonRequestBody
        )
        const lamalAccidentForm = action.payload

        yield put(setSubmitServiceStatus(LoadingStatusEnum.LOADING))

        const formToSubmit = new FormData()

        formToSubmit.append('PolicyNumber', ifNull(commonRequestBody.policyNumber, ''))
        formToSubmit.append('Include', lamalAccidentForm.include.toString())
        formToSubmit.append('EffectiveDate', lamalAccidentForm.effectiveDate)
        if (lamalAccidentForm.attachedFile) {
            formToSubmit.append('AttachedFile', lamalAccidentForm.attachedFile)
        }
        yield call(submitLamalAccidentForm, formToSubmit)
        yield put(setSubmitServiceStatus(LoadingStatusEnum.OK))
        yield put(formSubmitted(analyticsParams))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === HttpResponseEnum.CONFLICT) {
            yield put(getRequests())
        } else {
            console.error('onSubmitLamalAccidentSaga Error', e)
            yield put(setSubmitServiceStatus(LoadingStatusEnum.ERROR))
            yield spawn(sendEvent, analyticsConstants.EVENTS.FORM_SEND_ERROR, analyticsParams)
        }
    }
}

function* fetchModelDeductiblesWatcher() {
    yield takeEvery(fetchModelDeductibles.type, fetchModelDeductiblesAsync)
}

function* fetchMemberInformationWatcher() {
    yield takeEvery(fetchMemberInformation.type, fetchMemberInformationAsync)
}

function* onSubmitModelDeductibleWatcher() {
    yield takeEvery(onSubmitModelDeductible.type, onSubmitModelDeductibleSaga)
}

function* onSubmitLamalAccidentWatcher() {
    yield takeEvery(onSubmitLamalAccident.type, onSubmitLamalAccidentSaga)
}

const watchers = [
    fork(fetchModelDeductiblesWatcher),
    fork(fetchMemberInformationWatcher),
    fork(onSubmitModelDeductibleWatcher),
    fork(onSubmitLamalAccidentWatcher)
]

export default watchers

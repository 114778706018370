import './CustomerHeader.css'

import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { getECRoute } from 'core/helpers/NavigationHelper'
import useWindowSize from 'core/services/useWindowSize'
import AssuraIconButton from 'shared/components/AssuraIconButton/AssuraIconButton'
import AssuraIconButtonBelow from 'shared/components/AssuraIconButtonBelow/AssuraIconButtonBelow'
import { getPath } from 'shared/store/general/general.slice'
import {
    setKeepSelectedMember,
    setSelectedServiceForm,
    setSubmitServiceStatus
} from 'shared/store/services/services.slice'

const CustomerHeader = (): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isMobile } = useWindowSize()
    const { t } = useTranslation()
    const path = useSelector(getPath)

    const isHowItWorks = () => {
        return getECRoute(path)?.url === navigationConstants.LAMAL_RULES.url
    }

    const handleCloseAction = () => {
        dispatch(setSubmitServiceStatus(LoadingStatusEnum.OK))
        dispatch(setKeepSelectedMember(undefined))
        dispatch(setSelectedServiceForm(undefined))
        if (isHowItWorks()) {
            navigate(-1)
        } else {
            navigate(navigationConstants.SERVICES.url)
        }
    }

    const containerContent = () => (
        <div className="customer-header-container">
            <div
                className={`labelMedium flex-1 ${isHowItWorks() && 'howitworks-mobile'}`}
                style={{ textAlign: 'center' }}
            >
                {t(isHowItWorks() ? 'HOW_IT_WORKS.TITLE' : 'SERVICES.ONLINE_PROCEDURE')}
            </div>
            <div
                className="customer-header-close-container"
                {...(isHowItWorks() && { style: { marginRight: '32px' } })}
            >
                {isMobile ? (
                    <AssuraIconButton
                        id="services-customer-header-close-button"
                        icon="assura-close"
                        variant="default"
                        size="medium"
                        onClick={handleCloseAction}
                    />
                ) : (
                    <AssuraIconButtonBelow
                        id="services-customer-header-close-button"
                        icon="assura-close"
                        variant="default"
                        iconSize={24}
                        onClick={handleCloseAction}
                        label={t('COMMON.CLOSE')}
                        labelFontClass="labelExtraSmallMedium"
                        margin={4}
                    />
                )}
            </div>
        </div>
    )

    return (
        <div className="bbc-gray100 bg-white customer-header-full-container fixed-top">
            {isHowItWorks() ? (
                <>{containerContent()}</>
            ) : (
                <Container>{containerContent()}</Container>
            )}
        </div>
    )
}

export default CustomerHeader

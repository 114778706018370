import './OnlineServicesSplashScreen.css'

import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import navigationConstants from 'core/constants/navigationConstants'
import image from 'shared/assets/images/ec-homepage-spplashscreen-onlineservices.png'
import AssuraButton from 'shared/components/AssuraButton/AssuraButton'
import { getAssets } from 'shared/store/selectors/getAssets'

const OnlineServicesSplashScreen = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const assets = useSelector(getAssets)
    return (
        <Container
            className={`online-services-splashscreen-frame d-flex align-items-center justify-content-center`}
        >
            <Row className={`os-ss-row d-flex`}>
                <div className={`os-ss-text-col d-flex`}>
                    <div className={`os-ss-grey-panel-frame d-flex`}>
                        <div className={`os-ss-text-frame d-flex`}>
                            <div className="os-ss-text-punchline headlineLarge d-flex align-items-center justify-content-center m-bottom-24">
                                {t('SPLASHSCREEN.ONLINESERVICES.PUNCHLINE')}
                            </div>
                            <div className="os-ss-text-features paragraphLight d-flex align-items-center justify-content-center m-bottom-40">
                                {t('SPLASHSCREEN.ONLINESERVICES.FEATURES')}
                            </div>

                            <div className="os-ss-link d-flex justify-content-start">
                                <AssuraButton
                                    onClick={() => navigate(navigationConstants.SERVICES.url)}
                                    id="see-document-sample-button"
                                    variant="secondary"
                                    text={t('COMMON.LEARN_MORE')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`os-ss-image-col d-flex`}>
                    <div className={`os-ss-grey-panel-frame-overflow d-flex`} />

                    <div className={`os-ss-image-frame d-flex`}>
                        <div
                            className="os-ss-image d-flex"
                            style={{
                                backgroundImage: `url(${assets.SplashScreen_OnlineServices_Image?.fr ?? image})`
                            }}
                        ></div>
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default OnlineServicesSplashScreen

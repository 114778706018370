import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import navigationConstants from 'core/constants/navigationConstants'
import { DocumentsGroupEnum } from 'core/enums/Documents'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { DocumentsGroup } from 'core/models/documents/Document'
import { SideNavigationList } from 'core/models/SideNavigation'
import useQuery from 'core/services/useQuery'
import { assets } from 'core/utils/assetUtils'
import SideNavigationPage from 'shared/components/SideNavigationPage/SideNavigationPage'
import { getSummaryCurrentYear } from 'shared/store/combineSelectors'
import { getUnreadDocumentsLoadingStatus } from 'shared/store/documents/documents.slice'
import {
    fetchInvoicesToCheck,
    getInvoicesToCheckCount
} from 'shared/store/invoicesToCheck/invoicesToCheck.slice'
import { getUnreadDocumentsByGroup } from 'shared/store/selectors/getUnreadDocumentsByGroup'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import CommunicationsTable from '../components/CommunicationsTable/CommunicationsTable'
import InvoicesToCheckTable from '../components/InvoicesToCheckTable/InvoicesToCheckTable'

type CommunicationsItem = DocumentsGroup | 'invoicesToCheck'
const CorrespondanceTable = (): JSX.Element => (
    <CommunicationsTable documentsGroup={DocumentsGroupEnum.CORRESPONDENCE} />
)

const ContractualTable = (): JSX.Element => (
    <CommunicationsTable documentsGroup={DocumentsGroupEnum.CONTRACTUAL} />
)

const InvoicesTable = (): JSX.Element => <InvoicesToCheckTable />

const Communications = (): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const query = useQuery()
    const externalDefaultItem = query.get('item')

    const defaultId: CommunicationsItem =
        (externalDefaultItem as CommunicationsItem) ?? DocumentsGroupEnum.CORRESPONDENCE

    const unreadDocumentsLoadingStatus = useSelector(getUnreadDocumentsLoadingStatus)
    const currentSummary = useSelector(getSummaryCurrentYear)

    const generalLoadingStatus = getLoadingStatusFromLoaderList([unreadDocumentsLoadingStatus])

    const unreadDocumentsByGroup = useSelector(getUnreadDocumentsByGroup)
    const invoicesToCheckCount = useSelector(getInvoicesToCheckCount)

    const getBadge = (count: number) => {
        return count ? t('DOCUMENTS.UNREAD_COUNT', { count }) : undefined
    }

    const getInvoiceNotification = (count: number) => {
        return count
            ? t(`COMMUNICATIONS.INVOICES_TO_CHECK.COUNT${count > 1 ? '_PLURAL' : ''}`, { count })
            : undefined
    }

    const CommunicationsItems: SideNavigationList<CommunicationsItem> = new Map([
        [
            DocumentsGroupEnum.CORRESPONDENCE,
            {
                labelKey: 'COMMUNICATIONS.NAVIGATION_CORRESPONDENCE',
                icon: assets.email,
                badge: getBadge(unreadDocumentsByGroup.correspondence),
                Content: CorrespondanceTable
            }
        ],
        [
            DocumentsGroupEnum.CONTRACTUAL,
            {
                labelKey: 'INBOX.CATEGORY_FILTER_1',
                icon: assets.contract,
                badge: getBadge(unreadDocumentsByGroup.contractual),
                Content: ContractualTable
            }
        ],
        [
            'invoicesToCheck',
            {
                labelKey: 'COMMUNICATIONS.INVOICES_TO_CHECK',
                icon: assets.inscription,
                notification: getInvoiceNotification(invoicesToCheckCount),
                Content: InvoicesTable,
                isHidden: !currentSummary.displayInvoicesToCheck
            }
        ]
    ])

    useEffect(() => {
        navigate(location.pathname, { replace: true })
        dispatch(fetchInvoicesToCheck())
    }, [])

    return (
        <SideNavigationPage
            title="NAVIGATION_BAR.COMMUNICATIONS"
            defaultSelectedId={defaultId}
            items={CommunicationsItems}
            dataLoadingStatus={generalLoadingStatus}
            defaultUrl={navigationConstants.COMMUNICATIONS.url}
        />
    )
}

export default withAITracking(reactPlugin, Communications, 'Communications', 'side-navigation-page')

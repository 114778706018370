import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'

import { showQualiMedRemind, updateQualiMedRemind } from 'core/api/Account'
import { LoadingStatusEnum } from 'core/enums/LoadingStatusEnum'
import { DigcliParametersCms } from 'core/models/cms/DicliParametersCms'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { SummaryResult } from 'core/models/familyGroup/SummaryResult'
import { getSummaryCurrentYear } from 'shared/store/combineSelectors'
import { getDigcliparameters } from 'shared/store/digcliParameters/digcliParameters.slice'
import {
    getFamilyProductsLoadingStatus,
    setFamilyProductsLoadingStatus
} from 'shared/store/familySummaries/familySummaries.slice'

import {
    fetchMustDisplaySuccess,
    getQualiMedMustDisplay,
    onSubmitQualiMedRemindUpdate
} from './qualiMedRemind.slice'

export function* fetchMustDisplaySaga() {
    try {
        const mustDisplay: boolean | null = yield call(showQualiMedRemind)
        yield put(fetchMustDisplaySuccess({ mustDisplay }))
    } catch (e) {
        console.error('fetchMustDisplaySaga Error', e)
    }
}

export function* onSubmitQualiMedRemindUpdateSaga() {
    try {
        yield call(updateQualiMedRemind)
        yield put(fetchMustDisplaySuccess({ mustDisplay: false }))
    } catch (e) {
        console.error('onSubmitQualiMedRemindUpdateSaga Error', e)
    }
}

export function* qualiMedRemindPopinInit() {
    try {
        let shouldTriggerPopin = false

        const digcliParameters: DigcliParametersCms = yield select(getDigcliparameters)
        if (digcliParameters.QualimedPopinRulesActive === 'false') return shouldTriggerPopin

        const familyProductsLoadingStatus: LoadingStatusEnum = yield select(getFamilyProductsLoadingStatus)
        
        // TO WAIT UNTIL ALL MEMBER'S PRODUCTS ARE FETCHED
        if(familyProductsLoadingStatus === LoadingStatusEnum.LOADING) {
            yield take(setFamilyProductsLoadingStatus)
        }

        const familySummary: SummaryResult = yield select(getSummaryCurrentYear)

        if (familySummary && familySummary.insuredPersons.length !== 0) {
            // only search for QualiMed if products for all members were retrieved
            const familyGroupProducts = familySummary.insuredPersons.reduce<ProductPerson[]>(
                (acc, person) => {
                    if (person.productsPerson)
                        acc = acc.concat(person.productsPerson.products)
                    return acc
                },
                []
            )            

            if (familyGroupProducts.some((product) => product.code === 'PAM')) {
                yield call(fetchMustDisplaySaga)
                const mustDisplay: boolean | null = yield select(getQualiMedMustDisplay)

                if (mustDisplay) shouldTriggerPopin = true
            }
        }

        return shouldTriggerPopin
    } catch (e) {
        console.error('qualiMedRemindPopinInit Error', e)
        return false
    }
}

function* onSubmitQualiMedRemindUpdateWatcher() {
    yield takeEvery(onSubmitQualiMedRemindUpdate.type, onSubmitQualiMedRemindUpdateSaga)
}

const watchers = [fork(onSubmitQualiMedRemindUpdateWatcher)]

export default watchers

import './RecommendedProductCardList.css'

import { useEffect, useState } from 'react'
import { ResponsiveType } from 'react-multi-carousel'

import { RecommendedProduct } from 'core/models/cms/RecommendedProduct'
import useWindowSize from 'core/services/useWindowSize'
import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'

import RecommendedProductCard from '../RecommendedProductCard/RecommendedProductCard'

interface RecommendedProductCardListProps {
    data: RecommendedProduct[]
}

const RecommendedProductCardList = (props: RecommendedProductCardListProps) => {
    const windowSize = useWindowSize()
    const [hasEnoughItem, setHasEnoughItem] = useState(false)
    const [carouselItems, setCarouselItems] = useState<JSX.Element[]>([])

    const { data } = props

    useEffect(() => {
        if (windowSize.width) {
            if (windowSize.width >= 1344 && data?.length < 3) {
                setHasEnoughItem(false)
            } else if (windowSize.width >= 896 && windowSize.width < 1344 && data?.length < 2) {
                setHasEnoughItem(false)
            } else {
                setHasEnoughItem(true)
            }
        }
        const carouselItems = data?.map((recommendedProduct, index) => (
            <RecommendedProductCard
                recommendedProduct={recommendedProduct}
                key={index.toString()}
            />
        ))
        setCarouselItems(carouselItems)
    }, [windowSize, data])

    const responsive: ResponsiveType = {
        desktop: {
            breakpoint: { max: 10000, min: 1344 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1344, min: 896 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 896, min: 0 },
            items: 1
        }
    }

    return (
        <AssuraCarousel
            carouselItems={carouselItems}
            responsive={responsive}
            hasEnoughItem={hasEnoughItem}
            carouselContainerClass="recommended-product-card-carousel-container"
            carouselDotListClass="recommended-product-card-carousel-dot-list"
        />
    )
}

export default RecommendedProductCardList

import { useTranslation } from 'react-i18next'

import { ServicesHubItem } from 'core/models/services/ServicesHub'

import RequestsEcLink from '../RequestsEcLink/RequestsEcLink'

interface AllRequestsItemVersoProps {
    item: ServicesHubItem
}

const AllRequestsItemVerso = ({ item }: AllRequestsItemVersoProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column justify-content-start flex-1">
            <div className="d-flex justify-content-start align-items-center m-bottom-24">
                <img src={item.icon} alt={`${item.icon}-illustration`} width="48" height="48" />
                <span
                    className="headlineSmallMedium m-left-16 text-left"
                    data-testid={`services-hub-all-requests-item-verso-${item.id}-title`}
                >
                    {t(item.title)}
                </span>
            </div>
            <div className="requests-list-container">
                <>
                    {item.links.map((link) => (
                        <RequestsEcLink link={link} key={`request-ec-link-${link.ecLinkId}`}/>
                    ))}
                </>
            </div>
        </div>
    )
}

export default AllRequestsItemVerso

import 'react-multi-carousel/lib/styles.css'
import './AssuraCarousel.css'

import { useRef, useState } from 'react'
import Carousel, { ResponsiveType } from 'react-multi-carousel'

import AssuraCarouselArrow from './AssuraCarouselArrow'
import AssuraCarouselDot from './AssuraCarouselDot'

interface AssuraCarouselProps {
    carouselItems: JSX.Element[]
    responsive: ResponsiveType
    hasEnoughItem: boolean
    carouselContainerClass?: string
    carouselDotListClass?: string
    hasInfiniteLoop?: boolean
    renderDotsOutside?: boolean
    showDots?: boolean
}

const AssuraCarousel = ({
    carouselItems,
    responsive,
    hasEnoughItem,
    carouselContainerClass,
    carouselDotListClass,
    hasInfiniteLoop = false,
    renderDotsOutside = true
}: AssuraCarouselProps): JSX.Element => {
    const [controlledInfiniteLoop, setControlledInfiniteLoop] = useState(hasInfiniteLoop)
    const [playSpeed, setPlaySpeed] = useState(2000)

    const firstTransitionFlag = useRef(true)

    const preventInfiniteLoop = (): void => {
        setControlledInfiniteLoop(false)
    }

    const handleFirstTransition = (): void => {
        if (firstTransitionFlag.current) {
            firstTransitionFlag.current = false
            setPlaySpeed(5000)
        }
    }

    return (
        <Carousel
            responsive={responsive}
            itemClass="assura-carousel-item"
            containerClass={`assura-carousel-container${
                carouselContainerClass ? ` ${carouselContainerClass}` : ''
            }${!hasEnoughItem ? ' justify-content-center' : ''}`}
            showDots={hasEnoughItem}
            renderDotsOutside={renderDotsOutside}
            dotListClass={carouselDotListClass}
            customRightArrow={
                <AssuraCarouselArrow direction="right" preventInfiniteLoop={preventInfiniteLoop} />
            }
            customLeftArrow={
                <AssuraCarouselArrow direction="left" preventInfiniteLoop={preventInfiniteLoop} />
            }
            customDot={<AssuraCarouselDot />}
            pauseOnHover
            autoPlay={controlledInfiniteLoop}
            infinite={hasInfiniteLoop}
            autoPlaySpeed={hasInfiniteLoop ? playSpeed : undefined}
            shouldResetAutoplay={controlledInfiniteLoop}
            beforeChange={handleFirstTransition}
        >
            {carouselItems}
        </Carousel>
    )
}

export default AssuraCarousel

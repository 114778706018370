import './NavBarLangSelector.css'

import { useDispatch, useSelector } from 'react-redux'

import { LANGUAGES_FULL } from 'core/constants/lang'
import useWindowSize from 'core/services/useWindowSize'
import AssuraSelect, { SelectMap } from 'shared/components/AssuraSelect/AssuraSelect'
import { useAssuraNavBarContext } from 'shared/contexts/AssuraNavBarContext'
import { getLang, setLang } from 'shared/store/general/general.slice'

const NavBarLangSelector = (): JSX.Element => {
    const { isXL } = useWindowSize()
    const dispatch = useDispatch()
    const selectedLanguage = useSelector(getLang)
    const { setNavBarMenuState } = useAssuraNavBarContext()

    const languages = new Map() as SelectMap<string>

    for (const [lang, fullLang] of Object.entries(LANGUAGES_FULL)) {
        languages.set(fullLang, lang.toUpperCase())
    }
    if (process.env.REACT_APP_ENVIRONMENT?.toString() !== 'PRD') {
        languages.set('Keys', 'cimode')
    }

    const handleSelection = (lang: string) => {
        dispatch(setLang(lang.toLowerCase()))
    }

    const handleMobileSelection = (lang: string) => {
        setNavBarMenuState(false)
        dispatch(setLang(lang.toLowerCase()))
    }

    if (isXL) {
        return (
            <AssuraSelect
                id="navbar-lang"
                items={languages}
                value={selectedLanguage.toUpperCase()}
                onChange={(value) => value && handleSelection(value)}
                toggleClassnames="navbar-lang-selector-toggle"
                menuClassnames="navbar-lang-selector-menu labelSmall"
                shouldDisplaySelectionInMenu
                shouldDisplayValueInToggle
                alignRight
            />
        )
    }

    return (
        <div className="navbar-lang-mobile" data-testid="navbar-lang-mobile">
            {Array.from(languages.entries()).map(([label, v], index) => (
                <div
                    key={label}
                    data-testid={`navbar-lang-select-menu-item-${index}`}
                    className={`labelSmall lang ${
                        selectedLanguage.toUpperCase() === v ? 'current' : ''
                    }`}
                    onClick={() => handleMobileSelection(v)}
                >
                    {v}
                </div>
            ))}
        </div>
    )
}

export default NavBarLangSelector

import './BackToScanContainerMobile.css'

import { ResponsiveType } from 'react-multi-carousel'

import AssuraCarousel from 'shared/components/AssuraCarousel/AssuraCarousel'

interface BackToScanContainerMobileProps {
    items: JSX.Element[]
}

const BackToScanContainerMobile = ({ items }: BackToScanContainerMobileProps): JSX.Element => {
    const responsive: ResponsiveType = {
        mobile: {
            breakpoint: { max: 896, min: 0 },
            items: 1
        }
    }

    const hasMultipleItems = items.length > 1

    return (
        <AssuraCarousel
            carouselItems={items}
            responsive={responsive}
            hasEnoughItem={hasMultipleItems}
            carouselContainerClass={hasMultipleItems ? 'enveloppes-back-to-scan-container-mobile' : undefined}
            renderDotsOutside={false}
        />
    )
}

export default BackToScanContainerMobile

import { useSelector } from 'react-redux'

import analyticsConstants from 'core/constants/analyticsConstants'
import { ENGLISH_LANGUAGE } from 'core/constants/lang'
import { sendEvent } from 'core/helpers/AnalyticsHelper'
import { AssetUrlCms } from 'core/models/cms/AssetCms'
import { ProductPerson } from 'core/models/familyGroup/ProductPerson'
import { openInNewTab } from 'core/utils/onClickUtils'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getLang } from 'shared/store/general/general.slice'
import { getAssets } from 'shared/store/selectors/getAssets'
import { getShortContactLanguage } from 'shared/store/selectors/getShortContactLanguage'
import { useAppDispatch } from 'shared/store/store'

// Should be deleted once we fully implements November's How It Works
export const useHowItWorksPdfRedirection = (lamalProduct: ProductPerson | undefined) => {
    const dispatch = useAppDispatch()
    const lang = useSelector(getLang) as keyof AssetUrlCms
    const assets = useSelector(getAssets)
    const contactLang = useSelector(getShortContactLanguage) as keyof AssetUrlCms

    const AssetToLink = (item: string): string | undefined | null => {
        const result = Object.entries(assets).find((element) => element[0] === item)
        if (!result) return undefined

        if (lang === ENGLISH_LANGUAGE && contactLang && !result?.[1][ENGLISH_LANGUAGE]) {
            return result?.[1][contactLang]
        }
        return result?.[1][lang] as string
    }

    const handleNavigateToAsset = (): void => {
        const assetUrl = AssetToLink(lamalProduct?.infoLamal as string)
        if (!assetUrl) {
            dispatch(
                setBanner({
                    dataTestId: 'lamal-information-error',
                    message: 'COMMON.ERROR'
                })
            )
            return
        }
        sendEvent(analyticsConstants.EVENTS.OPEN_CCM_PDF, {
            [analyticsConstants.PARAMS.PRODUCT_TITLE]: lamalProduct?.title,
            [analyticsConstants.PARAMS.CCM_URI]: assetUrl
        })
        openInNewTab(assetUrl)
    }

    return { handleNavigateToAsset }
}

import axios from 'axios'
import cmsFields from 'core/constants/cmsFields'
import { AssetsCmsDocument } from 'core/models/cms/AssetCms'
import { DigcliParametersCms } from 'core/models/cms/DicliParametersCms'
import { EcLinkCms } from 'core/models/cms/EcLinkCms'
import { FaqCategoryCms } from 'core/models/cms/FaqCategoryCms'
import { FaqCms } from 'core/models/cms/FaqCms'
import { FrequentRequestCms } from 'core/models/cms/FrequentRequestCms'
import { GeneralQuestionTopic } from 'core/models/cms/GeneralQuestionTopicCms'
import { MaintenanceCollection } from 'core/models/cms/Maintenance'
import { NewsCms } from 'core/models/cms/NewsCms'
import { ProductCategoryCms } from 'core/models/cms/ProductCategoryCms'
import { ProductCms } from 'core/models/cms/ProductCms'
import { ProductGroupCms } from 'core/models/cms/ProductGroupCms'
import { ProductOverviewCms } from 'core/models/cms/ProductOverView'
import { QuickAccessCms } from 'core/models/cms/QuickAccessCms'
import { SearchSuggestionCms } from 'core/models/cms/SearchSuggestionCms'
import { WhatsNewCms } from 'core/models/cms/WhatsNewCms'

import { GoodToKnowCms } from '../models/cms/GoodToKnowCms'

export const productsCmsData = async (): Promise<ProductCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.PRODUCTS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/products`,
        fieldsFilter
    )
    return result.data
}

export const productsOverviewCmsData = async (): Promise<ProductOverviewCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.PRODUCTS_OVERVIEW
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/productsOverview`,
        fieldsFilter
    )
    return result.data
}

export const productCategoriesCmsData = async (): Promise<ProductCategoryCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.PRODUCT_CATEGORIES
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/productCategories`,
        fieldsFilter
    )
    return result.data
}

export const recommendedProductGroupsCmsData = async (): Promise<ProductGroupCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.PRODUCT_GROUPS,
        filter: { id: 'MOA' } //TODO: check with Estelle if something different should be created in the CMS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/productGroups`,
        fieldsFilter
    )
    return result.data
}

export const newsCmsData = async (): Promise<NewsCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.NEWS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/news`,
        fieldsFilter
    )

    return result.data
}

export const assetsCmsData = async (): Promise<AssetsCmsDocument[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.ASSETS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/sharedAssets`,
        fieldsFilter
    )

    return result.data
}

export const maintenanceCmsData = async (): Promise<MaintenanceCollection> =>
    (
        await axios.get<MaintenanceCollection>(
            `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/maintenanceMOA`
        )
    ).data

export const goodToKnowCmsData = async (): Promise<GoodToKnowCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.GOOD_TO_KNOW
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/goodToKnow`,
        fieldsFilter
    )

    return result.data
}

export const faqCategoryCmsData = async (): Promise<FaqCategoryCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.FAQ_CATEGORY
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/faqCategoryCms`,
        fieldsFilter
    )

    return result.data
}

export const faqCmsData = async (): Promise<FaqCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.FAQ
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/faqCms`,
        fieldsFilter
    )

    return result.data
}

export const searchSuggestionsCmsData = async (): Promise<SearchSuggestionCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.SUGGESTIONS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/suggestions`,
        fieldsFilter
    )

    return result.data
}

export const quickAccessCmsData = async (): Promise<QuickAccessCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.QUICKACCESS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/quickAccess`,
        fieldsFilter
    )

    return result.data
}

export const ecLinksCmsdata = async (): Promise<EcLinkCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.EC_LINKS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/ecLinks`,
        fieldsFilter
    )

    return result.data
}

export const frequentRequestsCmsData = async (): Promise<FrequentRequestCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.FREQUENT_REQUESTS
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/frequentRequests`,
        fieldsFilter
    )

    return result.data
}

export const digcliParameters = async (): Promise<DigcliParametersCms> => {
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/lokalize/project/digcliParameters`
    )

    // A cms lokalize was used for the parameters... that's why we have here a '.fr'
    return result.data.fr
}

export const generalQuestionTopicData = async (): Promise<GeneralQuestionTopic[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.GENERAL_QUESTION_TOPIC
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/generalQuestionTopic`,
        fieldsFilter
    )

    return result.data
}

export const whatsNewCmsData = async (): Promise<WhatsNewCms[]> => {
    const fieldsFilter = {
        simple: 1,
        fields: cmsFields.WHATS_NEW
    }
    const result = await axios.post(
        `${process.env.REACT_APP_CMS_SERVER}/api/collections/get/whatsNew`,
        fieldsFilter
    )

    return result.data
}

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { reactPlugin } from 'AppInsights'
import navigationConstants from 'core/constants/navigationConstants'
import { DocumentsGroupEnum } from 'core/enums/Documents'
import { MissingDataType } from 'core/enums/MissingData'
import { getLoadingStatusFromLoaderList } from 'core/helpers/LoadingHelper'
import { DocumentsGroup } from 'core/models/documents/Document'
import { SideNavigationList } from 'core/models/SideNavigation'
import useQuery from 'core/services/useQuery'
import { assets } from 'core/utils/assetUtils'
import SideNavigationPage from 'shared/components/SideNavigationPage/SideNavigationPage'
import { setBanner } from 'shared/store/banners/banners.slice'
import { getUnreadDocumentsLoadingStatus } from 'shared/store/documents/documents.slice'
import { getEnveloppeLoadingStatus } from 'shared/store/enveloppes/enveloppes.slice'
import { getEnveloppesCountByStatus } from 'shared/store/selectors/getEnveloppesCountByStatus'
import { getMissingDataInRequest } from 'shared/store/selectors/getMissingDataInRequest'
import { getUnpaidCount } from 'shared/store/selectors/getUnpaidCount'
import { getUnreadDocumentsByGroup } from 'shared/store/selectors/getUnreadDocumentsByGroup'
import { useAppDispatch } from 'shared/store/store'
import { getUnpaidStatsLoadingStatus } from 'shared/store/unpaidStats/unpaidStats.slice'

import { withAITracking } from '@microsoft/applicationinsights-react-js'

import FinancialsEnveloppes from '../components/FinancialsEnveloppes/FinancialsEnveloppes'
import FinancialsInvoices from '../components/FinancialsInvoices/FinancialsInvoices'
import FinancialsRefunds from '../components/FinancialsRefunds/FinancialsRefunds'

type FinancialsItem = DocumentsGroup | 'enveloppes'

const Financials = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const query = useQuery()
    const externalDefaultItem = query.get('item')

    const defaultId: FinancialsItem =
        (externalDefaultItem as FinancialsItem) ?? DocumentsGroupEnum.INVOICES

    const missingsDatas = useSelector(getMissingDataInRequest)

    const enveloppesLoadingStatus = useSelector(getEnveloppeLoadingStatus)
    const enveloppesCount = useSelector(getEnveloppesCountByStatus)
    const enveloppesInErrorCount = enveloppesCount.error

    const unreadDocumentsLoadingStatus = useSelector(getUnreadDocumentsLoadingStatus)
    const unreadDocumentsByGroup = useSelector(getUnreadDocumentsByGroup)

    const unpaidCountLoadingStatus = useSelector(getUnpaidStatsLoadingStatus)
    const unpaidCount = useSelector(getUnpaidCount)

    const globalLoadingStatus = getLoadingStatusFromLoaderList([
        enveloppesLoadingStatus,
        unreadDocumentsLoadingStatus,
        unpaidCountLoadingStatus
    ])

    const getBadge = (count: number) => {
        return count ? t('DOCUMENTS.UNREAD_COUNT', { count }) : undefined
    }

    const financialsItems: SideNavigationList<FinancialsItem> = new Map([
        [
            DocumentsGroupEnum.INVOICES,
            {
                labelKey: 'HOME.INVOICES_LAST_TITLE',
                icon: assets.document,
                badge: getBadge(unreadDocumentsByGroup.invoices),
                notification: unpaidCount ? t('HOME.NOTIFICATIONS_UNPAID_TITLE') : undefined,
                Content: FinancialsInvoices
            }
        ],
        [
            DocumentsGroupEnum.REFUNDS,
            {
                labelKey: 'HOME.SETTLEMENTS_LAST_TITLE',
                icon: assets.reimbursement,
                badge: getBadge(unreadDocumentsByGroup.refunds),
                Content: FinancialsRefunds
            }
        ],
        [
            'enveloppes',
            {
                labelKey: 'FINANCIALS.NAVIGATION_ENVELOPPES',
                icon: assets.send,
                notification: enveloppesInErrorCount
                    ? t('FINANCIALS.NAVIGATION_ENVELOPPES_NOTIFICATION', {
                          count: enveloppesInErrorCount
                      })
                    : undefined,
                Content: FinancialsEnveloppes
            }
        ]
    ])

    useEffect(() => {
        let missingValue: MissingDataType[] = []
        Object.values(missingsDatas).forEach((value) => (missingValue = missingValue.concat(value)))
        const isIbanMissing = missingValue.some((value) => value === MissingDataType.IBAN_MISSING)
        if (isIbanMissing) {
            dispatch(
                setBanner({
                    dataTestId: 'statements-iban-missing',
                    message: 'PERSONAL_DATA.MISSING_BANNER_IBAN'
                })
            )
        }
    }, [missingsDatas])

    useEffect(() => {
        // To clear url queries, to avoid to select the same filter when we select another document group
        navigate(location.pathname, { replace: true })
    }, [])

    return (
        <SideNavigationPage
            title="HOME.NOTIFICATIONS.FINANCES_TITLE"
            defaultSelectedId={defaultId}
            items={financialsItems}
            dataLoadingStatus={globalLoadingStatus}
            defaultUrl={navigationConstants.FINANCIALS.url}
        />
    )
}

export default withAITracking(reactPlugin, Financials, 'Financials', 'side-navigation-page')
